import { CurrencyPipe } from '@angular/common'
import { Component, Input } from '@angular/core'

@Component({
	selector: 'gwc-idp-option',
	templateUrl: './idp.option.component.html',
	styleUrls: ['./idp.option.component.scss'],
	standalone: true,
	imports: [
		CurrencyPipe
	]
})

export class IDPOptionComponent {
	@Input() option
}
