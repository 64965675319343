import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TravelRestrictionsComponent } from './travel.restrictions.component'
import { CardModule } from 'src/app/components/card/card.module'
import { HeadingModule } from 'src/app/components/heading/heading.module'
import { FormsModule } from '@angular/forms'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatButtonModule } from '@angular/material/button'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { ApplicantInfoComponent } from 'src/app/components/applicant.info/applicant.info.component'
import { SigninComponent } from 'src/app/components/signin/signin.component'

@NgModule({
  declarations: [
    TravelRestrictionsComponent
  ],
  imports: [
    ApplicantInfoComponent,
    CardModule,
    CommonModule,
    FormsModule,
    HeadingModule,
    MatButtonModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    SigninComponent
  ]
})

export class TravelRestrictionsModule { }
