import { Component, OnInit, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core'
import { OrderService } from 'src/app/services/order.service'
import { Subscription } from 'rxjs'
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms'
import { AuthnetService } from 'src/app/services/authnet.service'
import { MessageComponent } from 'src/app/dialogs/message/message.component'
import { PaypalService } from 'src/app/services/paypal.service'
import { AmazonService } from 'src/app/services/amazon.service'
import { AppleService } from 'src/app/services/apple.service'
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner'
import { environment } from 'src/environments/environment'
import { TermsDialogComponent } from 'src/app/dialogs/terms/terms.component'
import { TagService } from 'src/app/services/tag.service'
import { MatDialog } from '@angular/material/dialog'
import { ShippingService } from 'src/app/services/shipping.service'
import { AmazonComponent } from './amazon/amazon.component'
import { AppleComponent } from './apple/apple.component'
import { CurrencyPipe, NgClass } from '@angular/common'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatRadioModule } from '@angular/material/radio'
import { CreditCardComponent } from '../credit.card/credit.card.component'
import { ActivatedRoute, Router } from '@angular/router'
import { RefundComponent } from './refund/refund.component'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { LegalLinkComponent } from '../legal.link/legal.link.component'
import { DisclaimerComponent } from '../disclaimer/disclaimer.component'
import { PaymentMethod } from 'src/app/types/billing'
import { PhotoRouteData } from 'src/app/types/photo'
import { Facility } from 'src/app/types/facility'
import { PayPalComponent } from './paypal/paypal.component'
import 'whatwg-fetch'

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    AmazonComponent,
    AppleComponent,
    CreditCardComponent,
    RefundComponent,
    CurrencyPipe,
    FormsModule,
    MatExpansionModule,
    MatRadioModule,
    NgxSpinnerModule,
    NgClass,
    LegalLinkComponent,
    DisclaimerComponent,
    PayPalComponent
  ]
})

export class PaymentComponent implements OnInit, OnDestroy {
  public payment_methods: PaymentMethod[] = [
    "credit_card",
    // "paypal",
    // "amazon",
    // "apple",
    // "google"
  ]

  public links = environment.legal_links
  public submitted: boolean = false
  public cc_type: string = null
  public payment: FormGroup
  public billing_address: FormGroup
  public orderForm: FormGroup
  public shippingAddressAvailable: boolean
  public shippingAddressState: string | undefined
  public shippingAddressCountry: string | undefined
  public selected_service: PaymentMethod = this.orderService?.selectedService$.getValue() || 'credit_card'
  public show_spinner: boolean = false
  public currency: string = environment.source.currency || 'USD'
  public domain: string = environment.source.domain

  public months: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  public subscription: Subscription
  public routingData: PhotoRouteData = undefined
  public isRMPhotoEnv: boolean = environment?.source?.type === 'photo'
  public isRefundGuaranteeAvailable = environment?.source?.refund_guarantee
  public isPickupOptSelected: boolean = undefined
  public selectedFacility: Facility = undefined
  public mapURL: SafeResourceUrl = undefined

  constructor(
    public orderService: OrderService,
    private authnetService: AuthnetService,
    private dialogService: MatDialog,
    public paypalService: PaypalService,
    public amazonService: AmazonService,
    public appleService: AppleService,
    private tagService: TagService,
    private shippingService: ShippingService,
    activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {
    this.getPaymentMethods()
    this.payment = this.orderService.paymentForm
    this.billing_address = this.orderService.billingAddressForm
    this.orderForm = this.orderService.orderForm
    this.shippingAddressAvailable = this.shippingService.shippingForm.getRawValue().address.postal_code as unknown as boolean
    this.shippingAddressState = this.shippingService.shippingForm.getRawValue().address.state
    this.shippingAddressCountry = this.shippingService.shippingForm.getRawValue().address.country
    this.routingData =  activatedRoute.snapshot.data as PhotoRouteData
  }

  ngOnInit() {
    this.tagService.utagPageView('travel:expedited travel:payment')
    this.listenToSubmit()
    this.addRefunGuaranteeCtrl()
    this.initPickupOptSelected()
    this.getCustomerData()
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe()
    }
  }

  private listenToSubmit() {
    this.subscription = this.orderService.submittedSubject
      .subscribe((status: boolean) => {
        let validity = this.payment.valid
        this.submitted = true
        this.payment.markAllAsTouched()

        if (this.selected_service === 'credit_card') {
          this.billing_address.markAllAsTouched()

          validity = validity && this.billing_address.valid
        }

        if (this.isPickupOptSelected) {
          validity = this.payment.get('confirm')?.value
          this.orderService.setFormValidity(validity)
          if (validity) {
            this.orderService.createOrder(undefined, undefined, true)
          }
          else {
            this.orderService.scrollToFirstError()
          }
          return
        }

        this.orderService.setFormValidity(validity)

        if (validity) {
          switch (this.selected_service) {
            case 'credit_card':
              this.payment.patchValue({ method: 'AuthorizeNet' })
              this.creditCardValidation()
              break
            case 'paypal':
              this.payment.patchValue({ method: 'PayPalExpress' })
              this.paypalService.validateForm('paypal')
              break
            case 'venmo':
              this.payment.patchValue({ method: 'Venmo' })
              this.paypalService.validateForm('venmo')
              break
            case 'amazon':
              this.payment.patchValue({ method: 'AmazonPay' })
              this.amazonService.validateForm()
              break
          }
        } else {
          this.orderService.scrollToFirstError()
        }
      })
  }

  private initPickupOptSelected(): void {
    this.isPickupOptSelected = this.isRMPhotoEnv && this.orderService.isPickupOptSelected$.value
  }

  get heading(): string {
    return this.isRMPhotoEnv && this.isPickupOptSelected && this.routingData?.step === 3 ? this.routingData?.pickup?.heading : this.routingData?.heading
  }

  private addRefunGuaranteeCtrl(): void {
    if (this.hasPassportProducts() && this.isRefundGuaranteeAvailable) {
      this.orderForm.addControl('refund_guarantee', this.fb.control(true, Validators.required))
    }
  }

  private getCustomerData(): void {
    if (this.isPickupOptSelected) {
      const customerDataStr = sessionStorage.getItem('photo-customer-data')
      if (customerDataStr) {
        this.selectedFacility = JSON.parse(customerDataStr).selectedFacility
        this.updateMap(this.selectedFacility?.latitude, this.selectedFacility?.longitude)
      } else {
        this.router.navigateByUrl('/photo/step-3')
      }
    }
  }

  private updateMap(lat, long): void {
		const url = `https://www.google.com/maps/embed/v1/place?key=AIzaSyDrBuO_eyjOF8o1vZ2bdbOsc3xW6_ALheo&q=${lat},${long}`
    this.mapURL = this.sanitizer.bypassSecurityTrustResourceUrl(url)
	}

  private creditCardValidation() {
    const merchant_subscription = this.orderService.getMerchantToken(this.orderService.orderForm.controls.travelers.controls[0].controls.info.controls.residence_country.value === 'CA' ? 'CAD' : 'USD')
      .subscribe((merchant_response) => {
        const merchant = merchant_response.data

        const nonce_subscription = this.authnetService.nonceSubject
          .subscribe({
            next: (nonce: any) => {
              nonce_subscription.unsubscribe()
              merchant_subscription.unsubscribe()
              if (nonce && nonce.dataValue) {
                localStorage.setItem('cartStatus', 'submitting')
                this.orderService.createOrder(nonce, merchant)
              } else {
                this.orderService.orderCreateSubject.next({success: false})
                this.dialogService.open(
                    MessageComponent, {
                      width: 'auto',
                      data: {
                        title: `Credit Card Information Error`,
                        message: `Please make sure you entered your credit card information correctly.<br><br>
                          If you continue to experience problems, please call
                          <a href="${this.orderService.phone.uri}">${this.orderService.phone.number}</a> and we’ll help
                          you complete your order.`,
                        icon: `warning`
                      }
                    }
                )
              }
            },
            error: (error) => {
              this.orderService.orderCreateSubject.next({success: false})
              this.dialogService.open(
                MessageComponent, {
                  width: 'auto',
                  data: {
                    title: `Credit Card Information Error`,
                    message: `Please make sure you entered your credit card information correctly.<br><br>
                      If you continue to experience problems, please call
                      <a href="${this.orderService.phone.uri}">${this.orderService.phone.number}</a> and we’ll help
                      you complete your order.`,
                    icon: `warning`
                  }
                }
            )
              console.log(error)
            }
          })
        
        const zip = this.billing_address.get('same_as_shipping').value === true ? this.shippingService.shippingForm.get('address')?.get('postal_code').value : this.billing_address.get('address').get('postal_code').value
        this.authnetService.getNonce(merchant, this.payment.getRawValue(), zip)
      })
  }

  public paymentMethodChanged($event): void {
    this.scrollIntoElement($event?.source?._inputElement?.nativeElement)
    this.orderService.setFormValidators($event.value)
    if ($event && $event.value) {
      this.orderService.selectedService$.next($event.value)
    }
  }

  private scrollIntoElement(element: HTMLElement): void {
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 100);
    }
  }

  public getOrderTotal(): number {
    return this.orderService.getOrderTotalWithFees()
  }

  private getPaymentMethods() {
    for (const method in this.orderService.payment_methods_config) {
      switch (method) {
        case 'credit_card':
          continue
        case 'paypal':
          this.paypalInit(this.getPaymentConfig('paypal'))
          break
        case 'apple':
          this.appleInit(this.getPaymentConfig('apple'))
          break
        case 'amazon':
          this.amazonInit(this.getPaymentConfig('amazon'))
      }
    }
  }

  public getPaymentConfig(method: string) {
    return this.orderService.payment_methods_config[method]
  }

  private async paypalInit(config) {
    const response = await this.paypalService.init(config)

    this.payment_methods = [...this.payment_methods, ...response]
  }

  private amazonInit(config) {
    const cart = JSON.parse(localStorage.getItem('cart'))

    if (cart !== null) {
      this.amazonService.initConfig({
        sellerId: config.credentials.merchant_id,
        accessKey: config.credentials.access_key,
        clientId: config.credentials.client_id,
        mode: config.credentials.mode,
        domain: this.domain,
      })
    }

    this.payment_methods.push('amazon')
  }

  private appleInit(config) {
    if (window['ApplePaySession']) {
      const cart = JSON.parse(localStorage.getItem('cart'))
      if (cart !== null && this.appleService.initConfig(config)) {
        this.payment_methods.push('apple')
      }
    }
  }

  openDialog(type: string) {
    this.dialogService.open(TermsDialogComponent,{
      closeOnNavigation: true,
      data: {
        type
      }
    })
  }

  hasPassportProducts(): boolean {
    return this.orderService.hasPassportProducts
  }
}
