import { Component, OnInit, HostListener } from '@angular/core'
import { trigger, transition, animate, style } from '@angular/animations'
import { OrderService, PhoneObject } from 'src/app/services/order.service'
import { HeaderService } from 'src/app/services/header.service'
import { environment } from 'src/environments/environment'
import { NavigationEnd, Router } from '@angular/router'
import { EnvironmentHeader, EnvironmentLandingPage } from 'src/app/types/environment'
import { AuthService } from 'src/app/services/auth.service'
import { User } from 'src/app/types/user'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Component({
	selector: 'gw-chkout-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	animations: [
		trigger('slideInOut', [
			transition(':enter', [
				style({ transform: 'translateY(-100%)' }),
				animate('500ms ease-in', style({ transform: 'translateY(0%)'}))
			]),
			transition(':leave', [
				animate('500ms ease-in', style({ transform: 'translateY(-100%)' }))
			])
		])
	]
})

export class HeaderComponent implements OnInit {
	public scrolled: boolean = false
	public phone: PhoneObject = this.orderService.phone
	public countDown: string = '15:00'
	public message: boolean = false
	public header: EnvironmentHeader = environment.header
	public header_message: SafeHtml
	public website: string = environment.source?.main_website
	public user: User
	public funnel: boolean = window.location.href.includes('step')
	public settings: EnvironmentLandingPage
	public hide_phone: boolean = false

	constructor(
		private orderService: OrderService,
		private headerService: HeaderService,
		private authService: AuthService,
		router: Router,
		sanitizer: DomSanitizer
	) {
		if (this.header.message) {
			this.header_message = sanitizer.bypassSecurityTrustHtml(this.header.message)
		}

		router.events
			.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					this.funnel = event.urlAfterRedirects.includes('step')
					const type: string = event.urlAfterRedirects.includes('visa') ? 'visa' : environment.landing_pages.root_type
					this.settings = environment.landing_pages?.[type] || {}

					if (this.settings.header?.message) {
						this.header_message = sanitizer.bypassSecurityTrustHtml(this.settings.header.message)
					}

					this.hide_phone = event.urlAfterRedirects.includes('agent')
				}
			})
	}

	ngOnInit() {
		this.getUser()
		this.headerService.countDown.subscribe(countDown => {
			if (this.funnel) {
				this.countDown = countDown
				if(!this.message) this.message = true
			} else {
				this.message = false
			}
		})

		this.headerService.timerStopped.subscribe(() => {
			this.message = false
		})

		if (this.headerService.timer === true) {
			this.headerService.startTimer(true)
		}
	}

	private getUser(): void {
		this.authService.getUser()
			.subscribe((response) => {
				this.user = response
				this.listenToUserUpdates()
			})
	}

	private listenToUserUpdates() {
		this.authService.userSubject.subscribe((response) => {
			this.user = response
		})
	}

	@HostListener('window:scroll', ['$event'])
	onWindowScroll($event) {
		if (window.pageYOffset > 0) {
			return this.scrolled = true
		}

		return this.scrolled = false
	}
}
