import { NgModule } from '@angular/core'

import { ApiService } from './api.service'
import { CommonService } from './common.service'
import { OrderService } from './order.service'
import { PaypalService } from './paypal.service'
import { AmazonService } from './amazon.service'
import { AppleService } from './apple.service'
import { LocationsService } from './locations.service'
import { TagService } from './tag.service'
import { TealiumService } from './tealium.service'

@NgModule ({
  providers: [
    AmazonService,
    ApiService,
    AppleService,
    CommonService,
    LocationsService,
    OrderService,
    PaypalService,
    TagService,
    TealiumService
  ]
})

export class ServiceModule {}
