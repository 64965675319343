import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { FunnelRoutingModule } from './funnel-routing.module'
import { OrderComponent } from './order/order.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AccountComponent } from './account/account.component'
import { LocationNamePipeModule } from 'src/app/pipes/location.name/location.name.module'
import { SoldoutPipeModule } from 'src/app/pipes/soldout/soldout.module'
import { CardModule } from 'src/app/components/card/card.module'
import { MatListModule } from '@angular/material/list'
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatRadioModule } from '@angular/material/radio'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatButtonModule } from '@angular/material/button'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { CheckoutLayoutModule } from 'src/app/components/checkout.layout/checkout.layout.module'
import { CreditCardComponent } from 'src/app/components/credit.card/credit.card.component'
import { PassportOptionComponent } from 'src/app/components/passport.option/passport.option.component'
import { CATravelComponent } from './order/ca.travel/ca.travel.component'
import { GovFeeComponent } from './order/gov.fee/gov.fee.component'
import { GoogleAddressComponent } from 'src/app/components/google.address/google.address.component'
import { RouterModule } from '@angular/router'
import { IDPOptionComponent } from 'src/app/components/idp.option/idp.option.component'
import { DisclaimerComponent } from 'src/app/components/disclaimer/disclaimer.component'
import { VisaOptionComponent } from 'src/app/components/visa.option/visa.option.component'

@NgModule({
  declarations: [
    OrderComponent, 
    AccountComponent
  ],
  imports: [
    CATravelComponent,
    FormsModule,
    GovFeeComponent,
    ReactiveFormsModule,
    CardModule, 
    CATravelComponent,
    CheckoutLayoutModule,
    CommonModule,
    CreditCardComponent,    
    DisclaimerComponent,
    FunnelRoutingModule,
    LocationNamePipeModule,
    MatExpansionModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    NgxMaskDirective,
    NgxMaskPipe,
    PassportOptionComponent,
    SoldoutPipeModule,
    VisaOptionComponent,
    GoogleAddressComponent,
    RouterModule,
    IDPOptionComponent
  ],
  providers: [provideNgxMask()]
})

export class FunnelModule {}
