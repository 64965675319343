import { Component, Input, OnInit, signal, WritableSignal } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { RouterModule } from '@angular/router'
import { EnvironmentLandingPage, SigninLink } from 'src/app/types/environment'
import { environment } from 'src/environments/environment'
import { CardModule } from '../card/card.module'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'gw-chkout-home-signin',
	templateUrl: './signin.component.html',
	styleUrls: ['./signin.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		MatButtonModule,
		RouterModule,
		CardModule
	]
})

export class SigninComponent implements OnInit{
	@Input() type: string

	protected readonly signinUrl: string = environment.client_url
	public links: WritableSignal<string[]> = signal([])
	public linksStyle: WritableSignal<string> = signal('')
	public signin_label:  WritableSignal<SafeHtml> = signal('')
	public signin_btn_label: WritableSignal<string> = signal('')
	public compact: WritableSignal<boolean> = signal(true)
	public extra_links: WritableSignal<SigninLink[]> = signal([])

	constructor(
		private sanitizer: DomSanitizer
	){}

	ngOnInit(): void {
		const settings: EnvironmentLandingPage = environment.landing_pages?.[this.type]
		this.links.set(settings?.sign_in_links ? settings?.sign_in_links : [])
		this.signin_label.set(this.sanitizer.bypassSecurityTrustHtml(settings?.signin || "Sign in to our secure application portal."))
		this.signin_btn_label.set(settings?.signin_btn || 'SIGN IN')
		this.linksStyle.set(settings?.signin_link_style || 'uppercase')
		this.compact.set(environment.landing_pages?.[this.type]?.compact_signin)
		this.extra_links.set(settings?.signin_links || [])
	}
}
