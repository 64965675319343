<div class="gw-chkout-main gw-chkout-account">
  @if (login) {
    <div
      class="gw-chkout-account__login">
      <h3 class="gw-chkout-label">Already have an account with us?</h3>
      <button mat-button
        (click)="openLogin('Customer Sign In')"
        class="gw-chkout-button gw-chkout-button--grey gw-chkout-account__login-cta">
        LOGIN
      </button>
    </div>
  }
  <h3 class="gw-chkout-label">Contact Information</h3>
  <p>The information entered below is for the individual who is making the purchase, which may or may not be the applicant.</p>
  <form [formGroup]="user_profile" #userForm="ngForm">
    <div class="gw-chkout-form">
      <mat-form-field class="gw-chkout-field"
        [hideRequiredMarker]="true">
        <mat-label>First Name</mat-label>
        <input matInput
          pattern="[A-Za-z\-\s]*"
          formControlName="first_name">
        <mat-error>
          Please enter your first name.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="gw-chkout-field"
        [hideRequiredMarker]="true">
        <mat-label>Last Name</mat-label>
        <input matInput
          pattern="[A-Za-z\-\s]*"
          formControlName="last_name">
        <mat-error>
          Please enter your last name.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="gw-chkout-form">
      <mat-form-field class="gw-chkout-field">
        <mat-label>Company</mat-label>
        <input matInput
          placeholder="Company"
          formControlName="company">
        <mat-hint>Optional</mat-hint>
      </mat-form-field>
    </div>
    <div class="gw-chkout-form">
      <mat-form-field class="gw-chkout-field"
        [hideRequiredMarker]="true">
        <mat-label>Email</mat-label>
        <input matInput
          email
          (blur)="onEmailEntered()"
          type="email"
          formControlName="email"
          pattern="^\w+([\-+._']\w+)*@\w+([\-._]\w+)*\.\w+([\-.]\w+)*$">
        <mat-error>
          Please enter your email address.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="gw-chkout-form">
      <mat-form-field class="gw-chkout-field gw-chkout-field--half"
        [hideRequiredMarker]="true">
        <mat-label>Mobile Phone</mat-label>
        <input matInput
          formControlName="mobile"
          type="tel"
          minlength="10"
          mask="(000) 000-0000">
          <mat-error>
            Please enter a valid mobile phone number.
          </mat-error>
        </mat-form-field>
        <mat-form-field class="gw-chkout-field gw-chkout-field--half">
          <mat-label>Home or Office Phone</mat-label>
          <input matInput
            formControlName="office"
            type="tel"
            minlength="10"
            mask="(000) 000-0000">
          <mat-error>
            Please enter a valid phone number.
          </mat-error>
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
      </div>
      <div class="gw-checkout-account__disclaimer">
        <mat-checkbox class="gw-chkout-chkbox gw-chkout-chkbox--sms"
          color="primary"
          formControlName="sms_opt_in">
          Yes, please keep me updated on my order via automated text message. Message and data rates may apply.<span>*</span>
        </mat-checkbox>
        @if (domain === 'idp') {
          <mat-checkbox class="gw-chkout-chkbox gw-chkout-chkbox--sms"
            color="primary"
            formControlName="aaa_remarketing_opt_in">
            I would like to receive promotional material from AAA.
          </mat-checkbox>
        }
      </div>
    </form>
  </div>
