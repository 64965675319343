import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core'
import { OrderService } from 'src/app/services/order.service'
import { FormBuilder, FormGroupDirective} from '@angular/forms'
import { LoginComponent } from 'src/app/dialogs/login/login.component'
import { Subscription } from 'rxjs'
import { AuthService } from 'src/app/services/auth.service'
import { environment } from 'src/environments/environment'
import { TagService } from 'src/app/services/tag.service'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'gw-chkout-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})

export class AccountComponent implements OnInit, OnDestroy {
  @ViewChild('userForm') userForm: FormGroupDirective

  public user_profile
  public subscription: Subscription
  public login: boolean = environment.login?.enabled
  public domain: string = environment.source.domain

  constructor(
    fb: FormBuilder,
    private dialogService: MatDialog,
    private orderService: OrderService,
    private authService: AuthService,
    private tagService: TagService
  ) {
    this.user_profile = this.orderService.userForm
   }

  ngOnInit() {
    this.tagService.utagPageView('travel:expedited travel:customer info')

    this.listenToSubmit()
    this.orderService.getCart()
    this.autocompleteNames()
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe()
    }
  }

  listenToSubmit() {
    this.subscription = this.orderService.submittedSubject.subscribe((status: boolean) => {
      this.userForm.onSubmit(undefined)
      if (this.userForm.form.valid) {
        this.authService.checkIfUserExists({ email: this.userForm.value.email })
          .subscribe((response) => {
            if (response.data.exists) {
              this.orderService.setFormValidity(false)
              this.openLogin('Looks like you already have an account with us. Try signing in below.')
            } else {
              this.orderService.createHotLead()
              this.orderService.setFormValidity(true)
            }
          })
      } else {
        this.orderService.setFormValidity(false)
        this.orderService.scrollToFirstError()
      }
    })
  }

  openLogin(message) {
    const dialogRef = this.dialogService.open(
      LoginComponent, {
        width: 'auto',
        panelClass: 'gw-checkout-login-dialog',
        closeOnNavigation: true,
        data: {
          message,
          email: this.user_profile.controls.email.value
        }
      }
    )
  }

  autocompleteNames() {
    const travelers = this.orderService.orderForm.value.travelers
    if (travelers.length >= 1) {
      if (this.user_profile.get('first_name').value === '') {
        this.user_profile.get('first_name').value = travelers[0].info.first_name
      }
      if (this.user_profile.get('last_name').value === '') {
        this.user_profile.get('last_name').value = travelers[0].info.last_name
      }
    }
  }

  public onEmailEntered() {
    if (this.user_profile.controls.email.valid) {
      this.authService.checkIfUserExists({ email: this.user_profile.controls.email.value })
        .subscribe((response) => {
          if (response.data.exists) {
            this.openLogin('Looks like you already have an account with us. Try signing in below.')
          }
        })
    }
  }
}
