import { Component, Input } from '@angular/core'
import { VisaOption } from 'src/app/types/products'
import { HelperService } from 'src/app/services/helper.service'

@Component({
	selector: 'gw-chkout-visa-option',
	templateUrl: './visa.option.component.html',
	styleUrls: ['./visa.option.component.scss'],
	standalone: true
})

export class VisaOptionComponent {
	@Input() service: VisaOption
	@Input({required: true}) idx: number

	constructor(
		private helperService: HelperService
	) { }

	public beautifyRange(from: string, to: string): string {
		return this.helperService.beautifyRange(from, to)
	}
}
