import { Component } from '@angular/core'
import { FormArray, FormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { Observable, forkJoin } from 'rxjs'
import { CheckoutLayoutModule } from 'src/app/components/checkout.layout/checkout.layout.module'
import { AuthService } from 'src/app/services/auth.service'
import { FormService } from 'src/app/services/form.service'
import { OrderService } from 'src/app/services/order.service'
import { ProductService } from 'src/app/services/product.service'
import { FilterAttributeData } from 'src/app/types/products'
import { TravelerForm, TravelerProductForm } from 'src/app/types/traveler'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'gw-chkout-funnel',
  templateUrl: './funnel.component.html',
  styleUrls: ['./funnel.component.scss'],
  standalone: true,
  imports: [
    CheckoutLayoutModule
  ]
})

export class FunnelComponent {
  public loading: boolean = true

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private orderService: OrderService,
    private productService: ProductService
  ) {}

  ngOnInit() {
    this.checkUnique()
    this.getProducts()
  }

  private checkUnique(): void {
    let snapshot = this.route.snapshot
    while (snapshot.firstChild) {
      snapshot = snapshot.firstChild
    }

    let unique = Number.parseInt(snapshot.queryParamMap.get('unique'))
    if (unique === 1) {
      this.authService.logout()
    }
  }

  private getProducts() {
    this.orderService.getCart()

    let travelers: FormArray<FormGroup<TravelerForm>> = this.orderService.orderForm.controls.travelers
    let requests: Observable<any>[] = []

    if (travelers.length > 0) {
      for (let i = 0; i < travelers.length; i++) {
        let traveler: FormGroup<TravelerForm> = travelers.controls[i]
        let products: FormArray<FormGroup<TravelerProductForm>> = traveler.controls.products
        
        if (products.length > 0) {
          for (let j = 0; j < products.length; j++) {
            let product: FormGroup<TravelerProductForm> = products.controls[j]
            let residence_country: string = traveler.controls.info.controls.residence_country.value || 'US'
            let product_payload: FilterAttributeData = {
              type: product.controls.type.value,
              country: product.controls.country.value,
              citizenship: traveler.controls.info.controls.citizenship.value || 'US',
              residence_country
            }

            if (residence_country === 'US') {
              product_payload.residency = traveler.get('info').get('residency').getRawValue() || 'FL'
            }

            requests.push(this.productService.getProducts(product_payload))
          }
        }
      }
    }

    if (requests.length == 0) {
      const main_product = environment.main_product
      let traveler: FormGroup<TravelerForm> = travelers[0]

      if (traveler) {
        traveler.get('info').get('citizenship').patchValue(main_product?.citizenship || 'US')
      }

      let product_payload: FilterAttributeData = {
        type: main_product?.type || 'passport',
        country: main_product?.country || 'US',
        citizenship: main_product?.citizenship || traveler?.get('info')?.get('residency').getRawValue() || 'US',
        residence_country: main_product?.residence_country || 'US'
      }

      if (product_payload.residence_country === 'US') {
        product_payload.residency = traveler?.get('info')?.get('residency').getRawValue() || 'FL'
      }

      requests.push(this.productService.getProducts(product_payload))
    }

    requests.unshift(this.authService.getUser())

    forkJoin(requests).subscribe((response) => {
      this.orderService.user = response[0]
      this.loading = false
    })
  }

  public steps: string[] = ['Service Options', 'Customer Info', 'Shipping & Handling', 'Payment Method']
}
