import { Component, Input, OnInit, signal, WritableSignal } from '@angular/core'
import { FormBuilder, UntypedFormArray, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { CommonService } from 'src/app/services/common.service'
import { FormService } from 'src/app/services/form.service'
import { OrderService } from 'src/app/services/order.service'
import { ProductService } from 'src/app/services/product.service'
import { FilterAttributeData } from 'src/app/types/products'
import { environment } from 'src/environments/environment'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { EnvironmentLandingPage, LandingPageBGSrcSet } from 'src/app/types/environment'
import { HelperService } from 'src/app/services/helper.service'
import { ServiceCard } from 'src/app/models/service-card.model'

@Component({
	selector: 'gw-chkout-home-heading',
	templateUrl: './heading.component.html',
	styleUrls: ['./heading.component.scss']
})

export class HeadingComponent implements OnInit {
	@Input() type: string
	
	protected readonly companyName: string = environment.source.name
	protected readonly departureDate = this.helperService.getFollowingBusinessDays()
	protected readonly link: string = '/step-1'
	protected readonly defaults = {
		passport: {
			heading: `Passports With ${this.companyName}.`,
			description: `Traveling soon and need a passport? Begin your passport application now through ${this.companyName}.`
		},
		visas: {
			heading: `Traveling soon and need a travel visa?`,
			description: `Save time and a trip to the embassy and consulate.`
		},
		restrictions: {
			heading: 'International Travel Restrictions',
			description: `COVID-19 has transformed international travel and each country has implemented unique travel 
				restrictions and entry requirements. Use the form below to learn what’s required for your travel destination.`
		},
		idp: {
			heading: 'International Driving Permit',
			description: 'Planning to rent a car and venturing out into the world on your own? Plan to obtain an International Driving Permit before you go.'
		}
	}
	public hide_main_cta = signal(false)
	public heading: WritableSignal<SafeHtml> = signal('')
	public subDescription: WritableSignal<SafeHtml> = signal('')
	public description: WritableSignal<SafeHtml> = signal('')
	public cta_label: WritableSignal<string> = signal('')
	public variation: WritableSignal<number|null> = signal(null)
	public bg: WritableSignal<string> = signal('')
	public bg_srcset: WritableSignal<LandingPageBGSrcSet> = signal(null)
	public visaObj: WritableSignal<ServiceCard|null> = signal(null)
	public passportObj: WritableSignal<ServiceCard|null> = signal(null)
	public products = null
	public applicantForm = this.formBuilder.group({
		citizenship: ['US', Validators.required],
		residency: ['', Validators.required],
		country: ['', Validators.required],
	})

	constructor(
		private router: Router,
		private formService: FormService,
		private orderService: OrderService,
		private productService: ProductService,
		private commonService: CommonService,
		private helperService: HelperService,
		private sanitizer: DomSanitizer,
		private formBuilder: FormBuilder
	){}

	ngOnInit() {
		this.defineHeading()
		this.getTraveler()
		this.getProducts()
	}

	private getTraveler(): void {
		let traveler = this.orderService.orderForm.controls.travelers.controls[0]

		if (!traveler) {
			this.orderService.orderForm.controls.travelers.push(this.formService.getTravelerForm())
			traveler = this.orderService.orderForm.controls.travelers.controls[0]
		}
	}

	private getProducts(): void {
		if (['ca_passport', 'passport', 'idp'].includes(this.type)) {
			let product_object: FilterAttributeData
			
			if (environment.main_product) {
				const main_product = environment.main_product

				product_object = {
					type: main_product?.type || 'passport',
					country: main_product?.country || 'US',
					citizenship: main_product?.citizenship || 'US',
					residence_country: main_product?.residence_country || 'US',
					residency: ''
				}
			} else {
				product_object = this.type === 'passport' ? this.formService.getPassportProductRequest() : this.formService.getIDPProductRequest()
			}
			
			this.productService.getProducts(product_object)
				.subscribe(response => {
					this.products = response
				})
		} 
	}

	private defineHeading(): void {
		let settings: EnvironmentLandingPage = environment.landing_pages?.[this.type]

		this.hide_main_cta.set(settings?.hide_main_cta)
		this.heading.set(settings?.heading ? this.sanitizer.bypassSecurityTrustHtml(settings.heading) : this.defaults[this.type]?.heading)
		this.subDescription.set(settings?.subdescription ? this.sanitizer.bypassSecurityTrustHtml(settings.subdescription) : '')
		this.description.set(settings?.description ? this.sanitizer.bypassSecurityTrustHtml(settings.description) : this.defaults[this.type]?.description)
		this.cta_label.set(settings.cta_label || 'START APPLICATION')
		this.variation.set(settings?.variation || 1)
		if (settings.bg) {
			this.bg.set(settings.bg)
		} else if (settings.bg_srcset) {
			this.bg_srcset.set(settings.bg_srcset)
		}

		if (this.variation() === 4) {
			this.visaObj.set(environment.landing_pages?.[this.type].visa)
			this.passportObj.set(environment.landing_pages?.[this.type].passport)
		}
	}

	public onStartApplication() {
		const product_uuid: string = this.type === 'idp' ? this.products[0].uuid : ''
		const option_uuid: string = this.type === 'idp' ? this.products[0].services[0].uuid : ''
		const addons: any[] = this.type === 'idp' ? this.products[0].addons.filter(item => item.required).map(item => item.uuid) : []
		const product = this.formService.getProductForm(this.type)
		const main_product = environment.main_product
		
		if (main_product) {
			product.patchValue({
				type: this.type,
				product_uuid,
				option_uuid,
				subtype: this.type === 'idp' ? 'idp':'',
				country: main_product.country,
				addons
			})
		} else {
			product.patchValue({
				type: this.type,
				product_uuid,
				option_uuid,
				subtype: this.type === 'idp' ? 'idp':'',
				country: 'US',
				addons
			})
		}


		let traveler = this.orderService.orderForm.controls.travelers.controls[0]
		if (traveler) {
				let products = traveler.controls.products as UntypedFormArray
				let desired = products.value.filter(product => product.type === this.type)

				if (!desired[0]) {      
					products.push(product)
				}
		}

		let residency = traveler.controls.info.controls.residency

		if (!residency.value) {
			this.commonService.getRegionCode()
				.subscribe(response => {
					residency.patchValue(response)
					this.orderService.saveCart()
					return this.router.navigate([this.link], {queryParamsHandling: 'merge'})
				})
		} else {
			this.orderService.saveCart()
			return this.router.navigate([this.link], {queryParamsHandling: 'merge'})
		}
	}
}
