import { Component, OnInit, Renderer2, ElementRef, ViewChild, Input, ChangeDetectorRef, WritableSignal, signal } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, NgForm, FormGroup, AbstractControl } from '@angular/forms'
import { OrderService } from 'src/app/services/order.service'
import { Router } from '@angular/router'
import { HeaderService } from 'src/app/services/header.service'
import { environment } from 'src/environments/environment'
import { visa_types } from 'src/app/data/visa.types'
import { ProductService } from 'src/app/services/product.service'
import { ShippingService } from 'src/app/services/shipping.service'
import { visa_entries } from 'src/app/data/visa.entries'
import { AuthService } from 'src/app/services/auth.service'
import { ShippingForm } from 'src/app/types/shipping'
import { OrderCreateSubject } from 'src/app/types/billing'

@Component({
  selector: 'gw-chkout-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})

export class SummaryComponent implements OnInit {
  @Input() next: string
  @Input() step: number
  @Input() benefits: boolean

  public form_error: boolean = false
  public submitting: WritableSignal<boolean> = signal(false)
  public window_width
  public order
  public shipping: FormGroup<ShippingForm> 
  public promo_open: boolean = false
  public promo_error_message: string
  public summary_cta_icon: boolean = environment.summary_cta_icon
  public promo_code: boolean = environment.source.promo_code
  public entries = visa_entries
  public visa_types = visa_types
  public domain: string = environment.source.domain
  public currency: string = environment.source.currency || 'USD'
  public tax: {hst: number, qst?: number}
  public promoForm: UntypedFormGroup = this.fb.group({
    promoCode: ''
  })
  public checking_promo: boolean = false
  public discount
  public user
  public benefits_list = [
    {
      label: "Easy To Use",
      description: "Simply follow the link to take your photo using a mobile device! You will be able to review the photo before payment and retake until you are satisfied."
    },
    {
      label: "Meet Requirements",
      description: "Disapproved photos are the #1 reason for passport delays. Our biometric technology will make sure your passport photo is government-compliant. Don't get rejected."
    },
    {
      label: "Real Time Review",
      description: "Compliance checks with every photo taken. With real-time alerts and errors, you can ensure your photo will be perfect before downloading or printing."
    }
  ]
  public isRMPhotoEnv = environment?.source?.type === 'photo'
  public isRefundGuaranteeAvailable = environment?.source?.refund_guarantee

  @ViewChild('promoDrop', { read: ElementRef }) promoDrop: ElementRef
  @ViewChild('promoOpener') promoOpener: ElementRef
  @ViewChild('formDirective') private formDirective: NgForm

  constructor(
    private fb: UntypedFormBuilder,
    private renderer: Renderer2,
    private router: Router,
    public orderService: OrderService,
    private headerService: HeaderService,
    private productService: ProductService,
    private shippingService: ShippingService,
    private authService: AuthService,
    private ref: ChangeDetectorRef
  ) {
    this.order = this.orderService.orderForm
    this.shipping = this.shippingService.shippingForm

    if (this.orderService.coupon_code.promotion_code) {
      this.promoForm.controls.promoCode.patchValue(this.orderService.coupon_code.promotion_code)
      this.checkPromoCode()
    }
  }

  ngOnInit() {
    this.getUser()

    if (sessionStorage.getItem('timer') == 'true') {
      this.headerService.startTimer(true)
    }

    this.listenToTaxSubject()
    this.listenToFormValididty()
    this.listenToUserUpdates()
    this.listenToCouponValidity()

    this.window_width = window.innerWidth

    this.renderer.listen('window', 'resize', (e: Event) => {
      this.window_width = window.innerWidth
    })

    /*
     * The following code applies only to browsers that do not support position sticky (older versions of IE and Edge)
     */
    var summaryBlock = document.getElementsByTagName('aside')[0]
    var summayStyle = window.getComputedStyle(summaryBlock)
    var summaryPosition = summayStyle.getPropertyValue('position')
    var breakPoint = 1200

    if (summaryPosition === 'absolute') {
      var mainBox, windowY
      var initalPosition = ''

      setTimeout(function () {
        summaryBlock.style.left = (document.querySelector('.gw-chkout-main').clientWidth + 80) + 'px'
      }, 0)

      this.renderer.listen('window', 'scroll', (e: Event) => {
        if (this.window_width <= breakPoint) {
          return
        }

        var bumper = document.querySelector('.gw-chkout-funnel').clientHeight - summaryBlock.clientHeight -20
        var left, position, top
        mainBox = this.getDistanceToTop(document.querySelector('.gw-chkout-main'))
        windowY = (!window.scrollY) ? window.pageYOffset : window.scrollY
        position = 'absolute'
        left = (mainBox.width + 80) + 'px'

        if (windowY <= 180) {
          top = '180px'
        } else if (windowY > 180 && windowY <= bumper) {
          position = 'fixed'
          left = (mainBox.x + mainBox.width + 65) + 'px'
          top = '80px'
        } else {
          top = bumper + 'px'
        }

        summaryBlock.style.position = position
        summaryBlock.style.top = top
        summaryBlock.style.left = left
        initalPosition = position
      })

      this.renderer.listen('window', 'resize', (e: Event) => {
        mainBox = this.getDistanceToTop(document.querySelector('.gw-chkout-main'))

        if (this.window_width > breakPoint) {
          summaryBlock.style.left = (mainBox.x + mainBox.width + 65) + 'px'
          summaryBlock.style.position = initalPosition
        } else {
          summaryBlock.style.position = 'inherit'
        }
      })

    }
    // End IE code

    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.promo_open && !this.promoDrop.nativeElement.contains(e.target)) {
        this.promo_open = false
      }
     })
  }

  public getUser(): void {
    this.authService.getUser()
      .subscribe((response) => {
        this.user = response
      })
  }

  /**
   * getDistanceToTop - Calculate the distance from the top of the element to the top of tha page working back to IE 10, but also works on modern browsers
   *
   * @param  {type} element description
   * @return {type}         description
   */
  getDistanceToTop(element, isWindow?: boolean) {
      var xPosition = 0
      var yPosition = 0

      if (element) {
        var clientHeight = element.clientHeight
        var clientWidth = element.clientWidth
      }

      while(element) {
          xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft)
          yPosition += (element.offsetTop - element.scrollTop + element.clientTop)
          element = element.offsetParent
      }

      return { x: xPosition, y: yPosition, height: clientHeight, width: clientWidth }
  }

  listenToFormValididty() {
    this.orderService.formValiditySubject.subscribe((status: boolean) => {
      this.submitting.set(true)

      if (status) {
        this.form_error = false

        this.orderService.saveCart()
          .subscribe()

        if (this.next) {
          this.submitting.set(false)
          localStorage.setItem('reset', 'false')
          this.router.navigate([this.next])
        } else {
          this.orderService.orderCreateSubject.subscribe((status: OrderCreateSubject) => {
            if (!status.success) {
              this.submitting.set(false)
            }
          })
        }
      } else {
        this.submitting.set(false)
        this.form_error = true
      }
    })
  }

  listenToUserUpdates() {
    this.authService.userSubject.subscribe((response) => {
      this.user = response
    })
  }

  listenToCouponValidity() {
    this.orderService.couponSubject.subscribe((response) => {
      this.checking_promo = false

      if (response.status === 400) {
        this.promoForm.controls.promoCode.setErrors({'exists': false})
        this.promo_error_message = response.error.message
      } else {
        if (this.promo_open) {
          this.togglePromoForm()
        }

        if (response.discounts?.length == 1) {
          this.orderService.coupon_code = response
          this.discount = response.discounts[0]
        }
      }
    })
  }

  private listenToTaxSubject() {
    this.orderService.taxSubject.subscribe((tax) => {
      this.tax = tax
    })
  }

  togglePromoForm() {
    this.promo_open = !this.promo_open
    this.formDirective.resetForm()
    this.promoForm.reset()
  }

  checkPromoCode() {
    if (this.promoForm.valid) {
      this.checking_promo = true
      this.orderService.checkPromoCode(this.promoForm.value.promoCode)
    }
  }

  getProductDiscount(traveler, product) {
    if (!this.discount) {
      return 0
    }

    return this.orderService.getProductDiscount(traveler.value, product) * -1
  }

  get travelers() {
    return this.order.get('travelers') as UntypedFormArray
  }

  public isShippingRequired(traveler) {
    return this.shippingService.requireShippingAddress(traveler)
  }

  getProductDetails(traveler, product, detail: string, args: any = {}) {
    return this.productService.getProductDetails(traveler.value.info, product, detail, args)
  }

  getShippingPrices(bound: string) {
    return this.shippingService.getShippingPrice(this.travelers.value, bound)
  }

  getGovFee(traveler) { 
    let passport_product = traveler.value.products.filter(product => ['passport', 'ca_passport'].includes(product.type))

    if (passport_product[0]) {
      return this.productService.getProductDetails(traveler.value.info, passport_product[0], 'gov_fee')
    }

    return 0
  }

  submitForm() {
    this.submitting.set(true)
    this.orderService.setSubmitted()
  }

  checkIfPassportOrder() {
    // Are any travelers purchasing a passport product
    for (let traveler of this.travelers.value) {
      return traveler.products.some(product => {
        return ['passport', 'ca_passport'].includes(product.type)
      })
    }
  }

  checkIfGovFeeIncluded() {
    // Are any travelers purchasing a passport product
    for (let traveler of this.travelers.value) {
      return traveler.products.some(product => {
        let service_included = this.productService.getProductDetails(traveler.info, product, 'gov_fee_included')

        if (service_included) {
          return true
        }

        let addons = this.productService.getProductDetails(traveler.info, product, 'addons')
        let addons_array = product.addons

        return addons_array.some((addon) => {
          let addon_object = addons.find((item) => item.uuid == addon)

          if (addon_object) {
            return addon_object.slug == 'government_fee'
          }

          return false
        })
      })
    }
  }

  isStepThreeOrFour(): boolean {
    return this.step === 2 || this.step === 3
  }
  
  get refundGuarantee(): boolean {
    return this.orderService?.refundGuarantee
  }

  get hasPassportProducts(): boolean {
    return this.orderService?.hasPassportProducts
  }

  getOrderTotal(): number {
    if (this.step === 3) {
      return this.orderService?.getOrderTotalWithFees()
    }
    else {
      return this.orderService?.getOrderTotalWithoutFees()
    }
  }

  get odPrintLocationCtrl(): AbstractControl {
    return this.order?.controls?.travelers?.controls[0]?.controls?.products?.controls[0]?.controls?.od_print_location
  }
}
