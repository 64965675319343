export const QUESTIONS = { 
  aarp: [
    {
      title: "What is the benefit of using this service?",
      description: `The benefit of using a third party hand courier includes:<br/>
        <ul>
          <li>We offer U.S. Passport expediting services for every type of U.S. Passport.</li>
          <li>Our user-friendly online experience helps you navigate the application process.</li>
          <li>We help to ensure you have all the correct forms and requirements needed.</li>
          <li>We can help you avoid costly errors and common mistakes from going it alone.</li>
          <li>We offer dedicated support to assist you via phone, chat, and email.</li>
          <li>We proactively track the status of your application and help eliminate guesswork.</li>
          <li>We take the worry out of the process and allow you to focus on your next trip.</li>
        </ul>
        AARP members get access to exclusive discounts on passport services as well as white-glove concierge support to help ensure the simplest and easiest way to get your passport. 
      `
    },
    {
      id: "renew",
      title: "How do I renew my passport?",
      description: `You are eligible for a passport renewal if ALL of the following are true:<br/>
        <ul>
          <li>Your passport is currently valid, or has expired within the last five years.</li>
          <li>Your passport is in your possession.</li>
          <li>Your passport is not damaged.</li>
          <li>Your passport was issued when you were age 16 or older.</li>
        </ul>
      `
    },
    {
      id: "get",
      title: "What do I need to get a passport?",
      description: `The required documents depend on the passport type you need. Please see below for specific types and documents required.<br/><br/>
        <strong>For a New Adult Passport, Child Passport, and a Replacement Passport</strong>, you will need to visit a Passport Acceptance Agent (PAA) to have your documents adjudicated. You must include the required documentation including: proof of identity (Driver’s License or State Identification Card), proof of citizenship (Birth Certificate or Naturalization Paperwork), and if applicable, proof of legal name change. For a child passport, you must also provide proof of parental relationship (Parent’s Photo ID’s that must match child’s birth certificate).<br/><br/>
        <strong>Please note:</strong> Requesting one of these passport options requires the applicant to appear in front of a Passport Acceptance Agent (PAA) to have the documents adjudicated. This person is often a postal employee or an official at your local county clerk office or library. <br/><br/>
      `
    },
    {
      id: "adjudication",
      title: "What is Adjudication?",
      description: `Adjudication is the process of verifying the applicant's identity, documents, and signature. It is required for new passports, child passports, and replacing lost, stolen, or damaged passports.<br/><br/>
        To complete the adjudication process, you must visit a Passport Acceptance Agent (PAA), who will review and verify your documents, passport photo, and witness the signing of your passport application. The PAA will then take all of your documents and place them in a sealed package for submission to the U.S. Department of State. Passport Acceptance Agents usually include county clerks, libraries, or U.S. Post Offices. *Note: Do not sign your passport application until you are at the PAA and instructed to do so.<br/><br/>
        Depending on the RushMyPassport service speed selected, your documents will either be submitted to the U.S. Department of State through the PAA or RushMyPassport. If you have any questions or concerns, our team of expert passport advisors are available to help guide you through each step of the passport application process.<br/><br/>
        *Note: A separate adjudication fee of $35 is required for all New Adult, Child, Lost, Stolen, or Damaged passports.<br/><br/>
        To find a local PAA in your area please visit: <a href="https://iafdb.travel.state.gov/" target="_blank">https://iafdb.travel.state.gov/</a>
      `
    },
    {
      id: "guaranteed",
      title: "Am I guaranteed to get a U.S. Passport if I use this service?",
      description: `
        We guarantee that we will submit your application for processing based on the service type and expediting speed requested. However, there may be circumstances out of our control which can delay, suspend, or deny your application. Examples, other than weather, include owing child support or back taxes, having travel restrictions due to a felony, or not having the necessary identification.<br/><br/>
        Our team of expert passport advisors are here to guide you throughout the application process and assist with any questions, comments, or concerns you may have regarding your application. 
      `
    },
    {
      id: "cost",
      title: "How much does it cost to expedite a U.S. Passport?",
      description: `All U.S. Government fees are then paid separately in the form of check or money order, and are submitted along with your required documents.<br/><br/>
      U.S. Government fees for passport processing:<br/>
        <ul>
          <li>
            First-time adult applicants (16 years and older) and passport replacemnets:
            <ul>
              <li>$130 application fee</li>
              <li>$60 expediting fee</li>
              <li>Shipping fees (varies by service speed)</li>
              <li>$35 adjudication fee (paid separately to PAA)</li>
            </ul>
          </li>
          <li>
            First-time adult applicants (16 years and older):
            <ul>
              <li>$130 application fee</li>
              <li>$60 expediting fee</li>
              <li>Shipping fees</li>
            </ul>
          </li>
          <li>
            First-time adult applicants (16 years and older):
            <ul>
              <li>$100 application fee</li>
              <li>$60 expediting fee </li>
              <li>Shipping fees (varies by service speed)</li>
              <li>$35 adjudication fee (paid separately to PAA)</li>
            </ul>
          </li>
        <ul>
      `
    },
    {
      id: "name-change",
      title: "What if my name is different from what is listed on my passport?",
      description: `When completing your passport application, use your current legal name. In addition, you will need to provide the original name change document such as a marriage certificate, divorce decree or adoption paperwork.`
    },
    {
      id: "6-month-validity",
      title: "What is the 6-month validity rule?",
      description: `Most countries require your U.S. passport to have a minimum of 6-months validity after your intended return date. Please review your expiration date to see if a renewal is needed. The purpose of this validity is to safeguard against unforeseen delays you may face in leaving the country you are visiting. The State Department recommends that U.S. citizens renew their passports approximately nine months before the date of expiration.`
    },
    {
      id: "renew-child",
      title: "How do I renew my child’s passport?",
      description: `A child passport cannot be renewed. It is considered a new child passport each time until the applicant is 16 or older. At that time, the applicant can apply for a new adult passport. Please note that a visit to a Passport Acceptance Agent is required for all child passports.`
    },
    {
      id: "replace-lost",
      title: "How do I replace a lost passport?",
      description: `In order to replace a lost passport, the applicant will need to gather the required documents including proof of citizenship (birth certificate or naturalization paperwork) and proof of identity (Driver’s License or State Issued Identification) A visit to the Passport Acceptance Agent is required when replacing a lost or stolen passport. A separate fee of $35 is collected at this visit.`
    }
  ],
  fedex: [
    {
      id: "renew",
      title: "How do I renew my passport?",
      description: `If you have an adult passport (issued when the applicant was 16 or older with a ten-year validity) - and your passport is less than 5 years expired, you can renew your adult issued passport. For expedited renewal services, bring your expired or soon-to-expire passport, credit card and checkbook to your local FedEx Office. FedEx Office has partnered with RushMyPassport to offer a wide range of passport renewal processing speeds.`
    },
    {
      id: "get-passport",
      title: "What do I need to get a passport?",
      description: `The required documents depend on the passport type you need. Please see below for specific types and documents required.<br><br>
        <strong>For an Adult Passport Renewal</strong>, you can simply visit your local FedEx Office and bring your expired or soon-to-expire passport and check payment for the government fee. We can help prepare your new passport photo and the required application forms.<br><br>
        <strong>For a New Adult Passport, Child Passport, and a Replacement Passport</strong>, you will need to bring in additional documentation including: proof of identity (Driver’s License or State Identification Card), proof of citizenship (Birth Certificate or Naturalization Paperwork). For a child passport, you must provide proof of parental relationship (Parent’s Photo ID’s that must match child’s birth certificate).<br><br>
        <strong>Please note:</strong> requesting one of these passport options requires the applicant to appear in front of a Passport Acceptance Agent to have the documents adjudicated. This person is often a postal employee or an official at your local county clerk office. The adjudication process is an additional layer of security to ensure that the applicant is the person at this visit and that the documents have been reviewed and sealed to ensure appropriate chain of custody once it is received by RushMyPassport.<br><br>
        You can find your nearest passport acceptance agent <a target="_blank" href="https://iafdb.travel.state.gov/">here</a>.`
    },
    {
      id: "guaranteed",
      title: "Am I guaranteed to get a passport if I use this service?",
      description: `We guarantee that we will submit your application for expedited processing. However there are circumstances out of our control which can delay, suspend or deny your application.<br><br>
        Examples, other than weather, include owing child support or back taxes, having travel restrictions due to a felony, or not having the necessary identification.<br><br>
        When you use our service, we are notified immediately and can work with you to remedy the issue timely.`
    },
    {
      id: "passport-cost",
      title: "How much does it cost to expedite a passport?",
      description: `<strong>The government fee is an additional $190 ($130 + $60) for adults and $160 ($100 + $60) for children under 16. It is not included in the expediting service cost</strong>, is paid via personal check or money order directly to the US Department of State, and is included with your application when shipping to RushMyPassport.<br><br>
        If you wish to add a passport card, there is an additional fee of $30 for adults or $15 for children under 16.`
    },
    {
      id: "name-change",
      title: "What if my name is different from what is listed on my passport?",
      description: `If your name is different from what is listed on your birth certificate or has changed since the issuance of your most recent passport,  you can still renew your passport. When completing your passport application,  use your current legal name.  In addition, you will need to provide the original name change document such as a marriage certificate, divorcee decree or adoption paperwork.`
    },
    {
      id: "benefits",
      title: "What is the benefit of using this service?",
      description: `The benefit of using a third party hand courier includes:<br/>
      <ul>
        <li>Time and Convenience.  We save you time and a trip to the passport office.</li>
        <li>We help avoid 30% of delays by carefully reviewing your application for errors or omissions before submission.</li>
        <li>If there is an issue with your application we are notified by the passport office so we can fix any issues quickly.</li>
        <li>We provide smart-form automation, easy to use checklists and in-depth quality assurance.</li>
        <li>Tracking of your documents from start to finish.</li>
        <li>Customer support by phone, chat and email. We take the worry out of the process and allow you to focus on fun and adventure.</li>
      </ul>`
    },
    {
      id: "6-month-validity",
      title: "What is the 6-month validity rule?",
      description: `Most countries require your US passport to have a minimum of 6-months validity after your intended return date.  Please review your expiration date to see if a renewal is needed.   The purpose of this validity is to safeguard against unforeseen delays you may face in leaving the country you are visiting. The State Department recommends that U.S. citizens renew their passports approximately nine months before the date of expiration.`
    },
    {
      id: "renewal-eligible",
      title: "How do I know if my passport is eligible to be renewed?",
      description: `You are eligible for a passport renewal if ALL of the following are true:<br/>
      <ul>
        <li>Your passport is currently valid, or has expired within the last five years.</li>
        <li>Your passport is in your possession.</li>
        <li>Your passport is not damaged.</li>
        <li>Your passport was issued when you were age 16 or older.</li>
      </ul>`
    },
    {
      id: "renew-child",
      title: "How do I renew my child’s passport?",
      description: `A child passport cannot be renewed. It is considered a new child passport each time until the applicant is 16 or older.  At that time, the applicant can apply for a new adult passport. Please note that a visit to a Passport Acceptance facility is required for all child passports.`
    },
    {
      id: "fedex-aa",
      title: "Is FedEx Office a passport acceptance facility?",
      description: `No. FedEx Office is not a Passport Acceptance facility.  An Acceptance Facility is usually located within the county clerk office or US Post Office.  A separate fee of $35 is collected at this visit.<br><br>
        You can find your nearest passport acceptance agent <a target="_blank" href="https://iafdb.travel.state.gov/">here</a>.`
    },
    {
      id: "replace-lost",
      title: "How do I replace a lost passport?",
      description: `In order to replace a lost passport, the applicant will need to gather the required documents including proof of citizenship (birth certificate or naturalization paperwork) and proof of identity (Driver’s License of State Issued Identification) A visit to the Passport Acceptance facility is required when replacing a lost or stolen passport. A separate fee of $35 is collected at this visit.`
    }
  ],
  sandals: [
    {
      id: "renew",
      title: "How do I renew my passport?",
      description: "If you are eligible to renew your passport, you will be required to submit your expiring passport book, new passport photo, and required government fees along with your completed application."
    },
    {
      id: "eligible",
      title: "How do I know if my passport is eligible to be renewed?",
      description: `You are eligible for a passport renewal if <strong>ALL</strong> of the following are true:<br/>
        <ul>
          <li>Your passport is currently valid, or has expired within the last five years.</li>
          <li>Your passport is in your possession.</li>
          <li>Your passport is not damaged.</li>
          <li>Your passport was issued when you were age 16 or older.</li>
        </ul>`
    },
    {
      id: "get-adjudicated",
      title: "How do I get a New Adult Passport, Child Passport, or Replacement Passport?",
      description: `After obtaining a new passport photo, you will need to collect additional documentation including: proof of identity (Driver’s License or State Identification Card), proof of citizenship (Birth Certificate or Naturalization Paperwork). For a child passport, you must provide proof of parental relationship (Parent’s Photo ID’s that must match child’s birth certificate).<br/><br/>
        <strong>*Please note:</strong> Requesting one of these passport options requires the applicant to appear in front of a Passport Acceptance Agent to have the documents adjudicated. This person is often a postal employee or an official at your local county clerk office. The adjudication process is an additional layer of security to ensure that the applicant is the person at this visit and that the documents have been reviewed and sealed to ensure appropriate chain of custody once it is received by RushMyPassport.
        You can find your nearest passport acceptance agent <a href="https://iafdb.travel.state.gov/" target="_blank">here</a>.`
    },
    {
      id: "guarantee",
      title: "Am I guaranteed to get a passport if I use this service?",
      description: `We guarantee that we will submit your application for expedited processing. However there are circumstances out of our control which can delay, suspend or deny your application.<br/><br/>
        Examples, other than weather, include owing child support or back taxes, having travel restrictions due to a felony, or not having the necessary identification.<br/><br/>
        When you use our service, we are notified immediately and can work with you to remedy the issue timely.`
    },
    {
      id: "cost",
      title: "How much does it cost to expedite a passport?",
      description: `<strong>The government fee is an additional $190 ($130 + $60) for adults and $160 ($100 + $60) for children under 16. It is not included in the expediting service cost,</strong> is paid via personal check or money order directly to the US Department of State, and is included with your application when shipping to RushMyPassport.<br/>
        If you wish to add a passport card, there is an additional fee of $30 for adults or $15 for children under 16.`
    },
    {
      id: "name-change",
      title: "What if my name is different from what is listed on my passport?",
      description: "If your name is different from what is listed on your birth certificate or has changed since the issuance of your most recent passport,  you can still renew your passport. When completing your passport application,  use your current legal name.  In addition, you will need to provide the original name change document such as a marriage certificate, divorce decree or adoption paperwork."
    },
    {
      id: "benefits",
      title: "What is the benefit of using this service?",
      description: `The benefit of using a third party hand courier includes:<br/>
        <ul>
          <li>Time and Convenience.  We save you time and a trip to the passport office.</li>
          <li>We help avoid 30% of delays by carefully reviewing your application for errors or omissions before submission.</li>
          <li>If there is an issue with your application we are notified by the passport office so we can fix any issues quickly.</li>
          <li>We provide smart-form automation, easy to use checklists and in-depth quality assurance.</li>
          <li>Tracking of your documents from start to finish.</li>
          <li>Customer support by phone, chat and email. We take the worry out of the process and allow you to focus on fun and adventure.</li>
        </ul>`
    },
    {
      id: "6-month-validity",
      title: "What is the 6-month validity rule?",
      description: `Most countries require your US passport to have a minimum of 6-months validity after your intended return date.  Please review your expiration date to see if a renewal is needed.   The purpose of this validity is to safeguard against unforeseen delays you may face in leaving the country you are visiting. The State Department recommends that U.S. citizens renew their passports approximately nine months before the date of expiration.`
    },
    {
      id: "renew-child",
      title: "How do I renew my child’s passport?",
      description: `A child passport cannot be renewed. It is considered a new child passport each time until the applicant is 16 or older.  At that time, the applicant can apply for a new adult passport. Please note that a visit to a Passport Acceptance facility is required for all child passports.`
    },
    {
      title: "How do I replace a lost passport?",
      description: `In order to replace a lost passport, the applicant will need to gather the required documents including proof of citizenship (birth certificate or naturalization paperwork) and proof of identity (Driver’s License of State Issued Identification) A visit to the Passport Acceptance facility is required when replacing a lost or stolen passport. A separate fee of $35 is collected at this visit.`
    }
  ],
  default: [
    {
      id: "renew-passport",
      title: "How do I renew my passport?",
      description: `If you have an adult passport (issued when the applicant was 16 or older with a ten-year validity) - and your passport is less than 5 years expired, you are elegible for a passport renewal.`
    },
    {
      id: "get-passport",
      title: "What do I need to get a passport?",
      description: `The required documents depend on the passport type you need. Please see below for specific types and documents required.<br><br>
        <strong>For an Adult Passport Renewal</strong>, we can help prepare your new passport photo and the required application forms.<br><br>
        <strong>For a New Adult Passport, Child Passport, and a Replacement Passport</strong>, you will need to bring in additional documentation including: proof of identity (Driver’s License or State Identification Card), proof of citizenship (Birth Certificate or Naturalization Paperwork). For a child passport, you must provide proof of parental relationship (Parent’s Photo ID’s that must match child’s birth certificate).<br><br>
        <strong>Please note:</strong> requesting one of these passport options requires the applicant to appear in front of a Passport Acceptance Agent to have the documents adjudicated. This person is often a postal employee or an official at your local county clerk office. The adjudication process is an additional layer of security to ensure that the applicant is the person at this visit and that the documents have been reviewed and sealed to ensure appropriate chain of custody once it is received by RushMyPassport.<br><br>
        You can find your nearest passport acceptance agent <a target="_blank" href="https://iafdb.travel.state.gov/">here</a>.`
    },
    // {
    //   title: "What is an expedited passport service?",
    //   description: `Expedited Passport Service is processing less than 3 weeks. Faster services ranging from 1-12 days is available by using a private passport expediting service like RushMyPassport.<br><br>
    //     At the Agency, you must schedule an in-person appointment. Using RushMyPassport, no appointment is necessary, and it saves you a trip to the Passport Agency.`
    // },
    // {
    //   title: "Do I need to apply for an expedited passport?",
    //   description: `If you are traveling within the next 2 weeks (or 30 days where a visa is required), you must apply for an expedited passport. Routine passport processing times can take upwards to 8 weeks, based on time of year. For travelers that need their passport sooner, you should consider using an expedited option.`
    // },
    {
      id: "guaranteed",
      title: "Am I guaranteed to get a passport if I use this service?",
      description: `We guarantee that we will submit your application for expedited processing. However there are circumstances out of our control which can delay, suspend or deny your application.<br><br>
        Examples, other than weather, include owing child support or back taxes, having travel restrictions due to a felony, or not having the necessary identification.<br><br>
        When you use our service, we are notified immediately and can work with you to remedy the issue timely.`
    },
    {
      id: "passport-cost",
      title: "How much does it cost to expedite a passport?",
      description: `<strong>The government fee is an additional $190 ($130 + $60) for adults and $160 ($100 + $60) for children under 16. It is not included in the expediting service cost</strong>, is paid via personal check or money order directly to the US Department of State, and is included with your application when shipping to RushMyPassport.<br><br>
        If you wish to add a passport card, there is an additional fee of $30 for adults or $15 for children under 16.`
    },
    {
      id: "name-change",
      title: "What if my name is different from what is listed on my passport?",
      description: `If your name is different from what is listed on your birth certificate or has changed since the issuance of your most recent passport,  you can still renew your passport. When completing your passport application,  use your current legal name.  In addition, you will need to provide the original name change document such as a marriage certificate, divorcee decree or adoption paperwork.`
    },
    {
      id: "benefits",
      title: "What is the benefit of using this service?",
      description: `The benefit of using a third party hand courier includes:<br/>
      <ul>
        <li>Time and Convenience.  We save you time and a trip to the passport office.</li>
        <li>We help avoid 30% of delays by carefully reviewing your application for errors or omissions before submission.</li>
        <li>If there is an issue with your application we are notified by the passport office so we can fix any issues quickly.</li>
        <li>We provide smart-form automation, easy to use checklists and in-depth quality assurance.</li>
        <li>Tracking of your documents from start to finish.</li>
        <li>Customer support by phone, chat and email. We take the worry out of the process and allow you to focus on fun and adventure.</li>
      </ul>`
    },
    {
      id: "6-month-validity",
      title: "What is the 6-month validity rule?",
      description: `Most countries require your US passport to have a minimum of 6-months validity after your intended return date.  Please review your expiration date to see if a renewal is needed.   The purpose of this validity is to safeguard against unforeseen delays you may face in leaving the country you are visiting. The State Department recommends that U.S. citizens renew their passports approximately nine months before the date of expiration.`
    },
    {
      id: "renewal-eligible",
      title: "How do I know if my passport is eligible to be renewed?",
      description: `You are eligible for a passport renewal if ALL of the following are true:<br/>
      <ul>
        <li>Your passport is currently valid, or has expired within the last five years.</li>
        <li>Your passport is in your possession.</li>
        <li>Your passport is not damaged.</li>
        <li>Your passport was issued when you were age 16 or older.</li>
      </ul>`
    },
    {
      id: "renew-child",
      title: "How do I renew my child’s passport?",
      description: `A child passport cannot be renewed. It is considered a new child passport each time until the applicant is 16 or older.  At that time, the applicant can apply for a new adult passport. Please note that a visit to a Passport Acceptance facility is required for all child passports.`
    },
    {
      id: "replace-lost",
      title: "How do I replace a lost passport?",
      description: `In order to replace a lost passport, the applicant will need to gather the required documents including proof of citizenship (birth certificate or naturalization paperwork) and proof of identity (Driver’s License of State Issued Identification) A visit to the Passport Acceptance facility is required when replacing a lost or stolen passport. A separate fee of $35 is collected at this visit.`
    },
    // {
    //   title: "If I order Same Day - 24 hour processing, will I get my passport today?",
    //   description: `No. The 24 hour – Same Day service speaks to the timeframe of how long the passport is at the Department of State being processed. This time does not include shipping.  Please account for shipping your documents in for processing and then the overnight return shipping of your new passport.  If you are located in the continental United States, this is one additional day each way.`
    // },
    // {
    //   title: "What is the Letter of Authorization?",
    //   description: `The Letter of Authorization authorizes passport hand couriers to act as your representative when submitting your passport application. The United States Passport Agency requires that you sign this letter before it will release your new or updated passport.`
    // },
    // {
    //   title: "What is a passport acceptance facility?",
    //   description: `An Acceptance Facility is a location which can validate and seal your application for submission to the Department of State.  They are usually located within the county clerk office or US Post Office. A separate fee of $35 is collected at this visit.<br><br>
    //     You can find your nearest passport acceptance agent <a target="_blank" href="https://iafdb.travel.state.gov/">here</a>.`
    // },
    // {
    //   title: "What is the refund policy?",
    //   description: `If you are not eligible for our expedited passport services or wish to no longer continue with the process, full refunds are available.  However, once we have received your documents and/or they have been presented at the Passport Agency on your behalf, you are no longer entitled to a refund.`
    // }
  ]
}