import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { OrderService } from 'src/app/services/order.service'
import { HttpClient } from '@angular/common/http'
import { MessageComponent } from 'src/app/dialogs/message/message.component'
import { environment } from 'src/environments/environment'
import { catchError, firstValueFrom, map } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { loadScript, PayPalButtonsComponent, PayPalButtonsComponentOptions, PayPalNamespace } from '@paypal/paypal-js'
import * as _ from 'lodash'

@Injectable()

export class PaypalService extends ApiService {
	public transaction
	public sale
	public payPalButton: PayPalButtonsComponent|null = null
	public venmoButton: PayPalButtonsComponent|null = null

	constructor(
		http: HttpClient,
		private orderService: OrderService,
		private dialogService: MatDialog) {
		super(http)
	}

	public async init(config) {
		let response = []

		try {
			const paypal: PayPalNamespace = await loadScript({ 
				clientId: config.credentials.client_id,
				disableFunding: ['credit'],
				enableFunding: ['venmo'],
				intent: 'authorize',

			})

			const buttonConfig: PayPalButtonsComponentOptions = {
				createOrder: this.createOrderOnServer,
				onApprove: this.authorizeOnServer,
				onError: (err: any) => {
					console.error(err)
				}
			}

			this.payPalButton = paypal.Buttons({
				...buttonConfig,
				fundingSource: paypal.FUNDING.PAYPAL
			})

			if (this.payPalButton.isEligible()) {
				response.push('paypal')
			}

			this.venmoButton = paypal.Buttons({
				...buttonConfig,
				fundingSource: paypal.FUNDING.VENMO
			})

			if (this.venmoButton.isEligible()) {
				response.push('venmo')
			}
		} catch (error) {
		}

		return response
	}

	private createOrderOnServer = (data) => {
		return firstValueFrom(this.postRequest(`payment/paypal/create/transaction/${environment.source.domain}`, { data : [ this.orderService.getPurchaseUnits() ] })
			.pipe(
				map(response => {
					console.log('response: ', response)

					return response.id
				})
			))
	}

	private authorizeOnServer = (approveData) => {
		this.sale = approveData.orderID
		return firstValueFrom(this.postRequest(`payment/paypal/authorize/transaction/${environment.source.domain}`, {order_id: approveData.orderID})
			.pipe(
				map(response => {
					this.transaction = response.authorization_id
					this.orderService.paymentForm.controls.confirm.patchValue(true)
					this.orderService.setSubmitted()

					return response
				}),
				catchError(error => {
					this.openErrorDialog()

					throw error
				})
			))
	}

	public validateForm(merchant: string) {
		if (this.transaction) {
			localStorage.setItem('cartStatus', 'submitting')
			this.orderService.createOrder(this.transaction, { merchant, sale: this.sale })
		} else {
			this.orderService.orderCreateSubject.next({success: false})
			this.openErrorDialog()
		}
	}

	private openErrorDialog() {
		this.dialogService.open(
			MessageComponent, {
				width: 'auto',
				data: {
					title: `Authorization Error`,
					message: `The instrument presented  was either declined by the processor or bank, or it can't be used for this payment.
						<br><br>
						If you continue to experience problems, please call
						<a href="tel:800-220-1759">(800) 220-1759</a> and we’ll help
						you complete your order.`,
					icon: `warning`
				}
			}
		)
	}
}
