<section class="gwc-warning">
	<div class="gwc-warning-content gw-checkout-container">
		<img class="gwc-warning-content__img"
			src="https://gw-dev-assets.s3.amazonaws.com/passport-banner.jpg"/>
		<div class="gwc-warning-content__open">
			New Services Available.
		</div>
		<div class="gwc-warning-content__text">
			Now offering passport services in as little as 3 business days!
		</div>
	</div>
</section>
