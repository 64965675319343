<h3 class="gw-chkout-label">
  When do you need the passport?
</h3>
<mat-radio-group #radio
  aria-label="When do you need the passport?"
  [formControl]="processing_type"
  class="gw-chkout-radio"
  [class.gw-checkout-radio--submitted]="submitted">
  <mat-radio-button color="primary"
    class="gw-chkout-radio__item"
    value="expedited">
    <span class="gw-chkout-ca__option">
      <strong>1-9 Business Days</strong>
      <span>
        Must submit application in-person
      </span>
    </span>
  </mat-radio-button>
  <mat-radio-button color="primary"
    class="gw-chkout-radio__item"
    value="regular">
    <span class="gw-chkout-ca__option">
      <strong>10-20 Business Days</strong> 
      <span>
        Can mail application in for processing
      </span>
    </span>
  </mat-radio-button>
  <div class="gw-chkout__radio-message">
    Please select an expediting option.
  </div>
</mat-radio-group>