import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HeadingComponent } from './heading.component'
import { MatButtonModule } from '@angular/material/button'
import { ApplicantInfoComponent } from '../applicant.info/applicant.info.component'
import { CardModule } from '../card/card.module'
import { RouterModule } from '@angular/router'

@NgModule({
  declarations: [
    HeadingComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    ApplicantInfoComponent,
    CardModule,
    RouterModule
  ],
  exports: [
    HeadingComponent
  ]
})

export class HeadingModule { }
