import { Component } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { RouterModule } from '@angular/router'

@Component({
	selector: 'gw-chkout-warning',
	templateUrl: './warning.component.html',
	styleUrls: ['./warning.component.scss'],
	standalone: true,
	imports: [
    MatButtonModule,
    RouterModule
	]
})

export class WarningComponent {
}
