import { Injectable } from '@angular/core'
import { Validators, UntypedFormGroup, UntypedFormArray, FormGroup, FormBuilder } from '@angular/forms'
import { Subject, Observable, catchError, of, BehaviorSubject, map } from 'rxjs'
import { ApiService } from './api.service'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { validateDate } from '../validators/date.validator'
import { CreditCardValidators } from 'angular-cc-library'
import { Router } from '@angular/router'
import { MessageComponent } from '../dialogs/message/message.component'
import { environment } from 'src/environments/environment'
import { parsePhoneNumber } from 'libphonenumber-js'
import { HeaderService } from './header.service'

import * as _ from 'lodash'
import { TrackingService } from './tracking.service'
import { TagService } from './tag.service'
import { ProductService } from './product.service'
import { ShippingService } from './shipping.service'
import { ItineraryForm, OrderForm, Traveler, TravelerForm, TravelerProductForm } from '../types/traveler'
import { Product } from '../types/products'
import { NgxSpinnerService } from 'ngx-spinner'
import { FormService } from './form.service'
import { MatDialog } from '@angular/material/dialog'
import { HelperService } from './helper.service'
import { DateTime } from 'luxon'
import { OrderCreateSubject, PaymentMethod } from '../types/billing'
import { TagAPIService } from './tagapi.service'

export interface PhoneObject {
  uri: string
  number: string
}

@Injectable({
  providedIn: 'root'
})

export class OrderService extends ApiService {
  public submittedSubject: Subject<boolean> = new Subject<boolean>()
  public formValiditySubject: Subject<boolean> = new Subject<boolean>()
  public orderCreateSubject: Subject<OrderCreateSubject> = new Subject<OrderCreateSubject>()
  public couponSubject: Subject<any> = new Subject<any>()
  public taxSubject: Subject<any> = new Subject<any>()
  public taxObject: {hst: number, qst?: number}
  public selectedService$ = new BehaviorSubject<PaymentMethod>('credit_card')

  public passportProduct = {
    type: 'passport',
    country: 'US',
    citizenship: 'US',
    residency: '', //'FL'
    residence_country: 'US',
  }

  public phone: PhoneObject
  public previous: string
  public user: any
  public orderForm: FormGroup<OrderForm>
  public userForm
  public paymentForm
  public coupon_code: any = {}
  public product_form = this.formBuilder.group<TravelerProductForm>({
    type: this.formBuilder.control('', Validators.required),
    country: this.formBuilder.control('', Validators.required),
    product_uuid: this.formBuilder.control('', Validators.required),
    option_uuid: this.formBuilder.control('', Validators.required),
    addons: this.formBuilder.control([]),
    subtype: this.formBuilder.control(''),
    initial: this.formBuilder.control(true)
  })

  public billingAddressForm = this.formBuilder.group({
    same_as_shipping: [true, Validators.required]
  })

  public payment_methods_config: any = {}
  public products = {}
  public readonly REFUND_GUARANTEE_RATE = 0.065
  public isPickupOptSelected$ = new BehaviorSubject<boolean>(undefined)

  constructor(
    http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialogService: MatDialog,
    private headerService: HeaderService,
    private trackingService: TrackingService,
    private tagService: TagService,
    private productService: ProductService,
    private shippingService: ShippingService,
    private spinner: NgxSpinnerService,
    private formService: FormService,
    private helperService: HelperService,
    private tagAPIService: TagAPIService
  ) {
    super(http)
    this.buildOrder()
    this.getPaymenMethodsConfig()
    this.parsePhone()
    this.listenForErrors()
  }

  private listenForErrors() {
    this.productService.clearProductSubject.subscribe(response => {
      if (response.product) {
        this.orderForm.controls.travelers.controls.forEach(traveler => {
          traveler.controls.products.controls.forEach(product => {
            if (product.value.product_uuid === response.product) {
              product.controls.product_uuid.reset()
              product.controls.option_uuid.reset()
            }
          })
        })

        this.saveCart()
      }
    })

    this.productService.clearOptionSubject.subscribe(response => {
      if (response.option) {
        this.orderForm.controls.travelers.controls.forEach(traveler => {
          traveler.controls.products.controls.forEach(product => {
            if (product.value.option_uuid === response.option) {
              product.controls.option_uuid.reset()
            }
          })
        })

        this.saveCart()
      }
    })
  }

  buildOrder() {
    this.orderForm = this.formBuilder.group<OrderForm>({
      verified: this.formBuilder.control(false),
      external: this.formBuilder.control(''),
      shipping: this.formBuilder.control(''),
      travelers: this.formBuilder.array<FormGroup<TravelerForm>>([], Validators.required),
      itinerary: this.formBuilder.group<ItineraryForm>({
        country: this.formBuilder.control({value: '', label: ''}),
        start_date: this.formBuilder.control('',[
            validateDate(this.helperService.getFollowingBusinessDays(2).toFormat('yyyy-MM-dd'), '2038-12-31')
          ]
        )
      }),
      params: this.formBuilder.control({}),
    })

    this.userForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      sms_opt_in: [true , Validators.pattern('true|false')],
      company: [''],
      office: ['']
    })

    if (environment.source.domain === 'idp') {
      this.userForm.addControl('aaa_remarketing_opt_in', this.formBuilder.control(true, Validators.pattern('true|false')))
    }

    this.paymentForm = this.formBuilder.group({
      cc_number: ['', [
        Validators.required,
        CreditCardValidators.validateCCNumber
      ]],
      cc_name: ['', Validators.required],
      cc_exp_month: ['', Validators.required],
      cc_exp_year: ['', Validators.required],
      cc_ccv: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(4)
      ]],
      confirm: [false , Validators.pattern('true')],
      method: 'credit_card'
    })
  }

  setFormValidators($paymentMethod) {
    const cc_number = this.paymentForm.get('cc_number')
    const cc_name = this.paymentForm.get('cc_name')
    const cc_exp_month = this.paymentForm.get('cc_exp_month')
    const cc_exp_year = this.paymentForm.get('cc_exp_year')
    const cc_ccv = this.paymentForm.get('cc_ccv')

    switch ($paymentMethod) {
      case 'credit_card':
        cc_number.setValidators([ Validators.required, CreditCardValidators.validateCCNumber ])
        cc_name.setValidators([ Validators.required ])
        cc_exp_month.setValidators([ Validators.required ])
        cc_exp_year.setValidators([ Validators.required ])
        cc_ccv.setValidators([ Validators.required, Validators.minLength(3), Validators.maxLength(4) ])
        break
      case 'venmo':
      case 'paypal':
      case 'amazon':
        cc_number.setValidators(null)
        cc_name.setValidators(null)
        cc_exp_month.setValidators(null)
        cc_exp_year.setValidators(null)
        cc_ccv.setValidators(null)
        break
    }
    cc_number.updateValueAndValidity()
    cc_name.updateValueAndValidity()
    cc_exp_month.updateValueAndValidity()
    cc_exp_year.updateValueAndValidity()
    cc_ccv.updateValueAndValidity()
  }

  setSubmitted() {
    this.submittedSubject.next(true)
  }

  getProductData(product, segment: string) {
    if (segment == 'products') {
      return this.products[product.country][product.type][segment]
    } else {
      let items = this.products[product.country][product.type].products

      if (product.product_uuid) {
        let filtered = _.filter(items, ['uuid', product.product_uuid])

        if (filtered.length == 0) {
          // Unset Cart if product is not found
          localStorage.removeItem('cart')
          return items[0][segment]
        }

        return filtered[0][segment]
      } else {
        return items[0][segment]
      }
    }
  }

  getServiceDetails(product, segment) {
    if (product.option_uuid) {
      let items = this.getProductData(product, 'options')
      let filtered = _.filter(items, ['uuid', product.option_uuid])

      if (filtered.length == 0) {
        // Unset Card if product is not found
        localStorage.removeItem('cart')
        return null
      }

      return filtered[0] ? filtered[0][segment] : null
    }

    return null
  }

  getAddonDetails(product, addon, segment) {
    let items = this.getProductData(product, 'addons')
    let filtered = _.filter(items, ['uuid', addon])

    return filtered[0][segment]
  }

  setFormValidity(validity: boolean): void {
    this.formValiditySubject.next(validity)
  }

  preCheckInsurance(product: UntypedFormGroup): UntypedFormGroup {
    let products = this.productService.checkForProducts(this.passportProduct),
        addons = [],
        passport_insurance = _.filter(products[0].addons, ['icon', 'passport-insurance'])

    if (passport_insurance[0]) {
      addons.push(passport_insurance[0].uuid)
    }

    if (addons.length > 0) {
      product.patchValue({addons: addons, initial: false})
      return product
    }
  }

  saveCart(): Observable<any> {
    let cart: any = this.orderForm.value
    cart.shipping = this.shippingService.shippingForm.value
    cart.user_profile = this.userForm.value
    cart.promotions = this.coupon_code

    localStorage.setItem('cart', JSON.stringify(cart))

    return this.postRequest('user/cart', { cart })
  }

  getCart() {
    // let cart = this.getRequest('user/cart')
    //   .subscribe((blah) => {
    //     console.log('cart', blah)
    //   })

    let cart = JSON.parse(localStorage.getItem('cart'))

    if (cart) {
      if (cart.itinerary && cart.itinerary.start_date) {
        if (DateTime.fromFormat(cart.itinerary.start_date, 'MM/dd/yyyy') < DateTime.now()) {
          cart.itinerary.start_date = ''
          this.saveCart()
        }
      }

      this.orderForm.controls.travelers.clear()

      if (cart.travelers.length > 0) {
        cart.travelers.forEach((traveler: Traveler) => {
          let traveler_object = this.formService.getTravelerForm()

          traveler.products.forEach((product) => {
            let product_object: UntypedFormGroup = this.formService.getProductForm(product.type, product.od_print_location)
            this.isPickupOptSelected$.next(!!product.od_print_location)
            product_object.patchValue(product)
            let products = traveler_object.controls.products as UntypedFormArray
            products.push(product_object)
          })

          this.orderForm.controls.travelers.push(traveler_object)
        })

        this.orderForm.patchValue(cart)

        if (cart.shipping?.is_military) {
          this.shippingService.addMilitaryAddressControl()
        }

        this.shippingService.shippingForm.patchValue(cart.shipping)
        if (cart.shipping?.inbound_price) {
          this.shippingService.addShippingPriceControl(cart.shipping.inbound_price, 'inbound_price')
        }

        if (cart.shipping?.speed_price) {
          this.shippingService.addShippingPriceControl(cart.shipping.speed_price, 'speed_price')
        }

        this.userForm.patchValue(cart.user_profile)
        this.coupon_code = cart.promotions ? cart.promotions : {}
      } else {
        if (cart.params) {
          this.orderForm.controls.params.patchValue(cart.params)
        }

        cart = null
      }
    }
    
    return cart
  }
    
  // Determine whether od_print_location is selected (exists) or not for RushMyPhoto only
  listenToPickupOptChanges() {
    if (environment?.source?.type === 'photo' && this.orderForm?.controls?.travelers?.controls && this.orderForm?.controls?.travelers?.controls[0]?.controls?.products?.controls) {
      this.orderForm?.controls?.travelers?.controls[0]?.controls?.products?.controls[0]?.valueChanges.subscribe(v => {
        this.isPickupOptSelected$.next('od_print_location' in v)
      })
    }
  }

  getMerchantToken(currency: string, apple_pay: boolean = false) {
    return this.postRequest(`order/get/token`, { domain: environment.source.domain, currency, apple_pay})
  }

  getPaymentMethods() {
    return this.getRequest(`payment/methods/${environment.source.domain}`)
  }

  createHotLead(external = false) {
    if (environment.create_lead) {
      const domain = environment.source.domain
      let cart: any = this.orderForm.value
      cart.user_profile = this.userForm.value
      cart.source = domain

      cart.gw_tracker = this.trackingService.getTracking('gwTracker')

      if (domain === 'rmp') {
        cart.linkshare = this.trackingService.getLinkShare()
      }

      if (domain === 'rushmyphoto' && external === true) {
        cart.external = true
      }

      this.postRequest('v2/hotlead', cart)
        .subscribe({
          next: () => {},
          error: (error: HttpErrorResponse) => {
            if (error.status === 404) {
              // Reset cart if product not found
              this.orderForm.controls.travelers.controls.forEach((traveler: FormGroup<TravelerForm>) => {
                traveler.controls.products.controls.forEach((product: FormGroup) => {
                  product.get('product_uuid').reset()
                  product.get('option_uuid').reset()
                  product.get('addons').patchValue([])

                  this.saveCart()
                  window.location.href = '/step-1'
                })
              })
            }
          }
        })    
    }
  }

  createOrder(nonce?, merchant?, external = false) {
    const domain = environment.source.domain
    let cart: any = this.orderForm.value
    cart.shipping = this.shippingService.shippingForm.value
    cart.billing = this.billingAddressForm.getRawValue()
    cart.user_profile = this.userForm.value
    cart.promotions = this.coupon_code

    // cart.payment_processor = this.paymentForm.get('method').value
    if (!this.userForm.value.email && this.user) {
      cart.user_profile.email = this.user.email
    }

    cart.source = domain
    cart.merchant = merchant?.merchant

    switch (cart.merchant) {
      case 'paypal':
        cart.payment_processor = 'PayPalExpress'
        break
      case 'venmo':
        cart.payment_processor = 'Venmo'
        break
      case 'amazon':
        cart.payment_processor = 'AmazonPay'
        break
      default:
        cart.payment_processor = 'AuthorizeNet'
        break
    }

    if (cart.payment_processor === 'AuthorizeNet') {
      cart.payment_token = nonce?.dataValue
      cart.payment_descriptor = nonce?.dataDescriptor
    } else {
      cart.transaction_id = nonce
      cart.sale_id = merchant?.sale
    }

    cart.gw_tracker = this.trackingService.getTracking('gwTracker')

    if (domain === 'rmp') {
      cart.linkshare = this.trackingService.getLinkShare()
    }

    const endpoint = external ? 'order/external/create' : 'order/unified/create'
    let order_create_subscription = this.postRequest(endpoint, cart)
      .subscribe({
        next: (response) => {
          if (cart.payment_processor === 'AmazonPay') {
            document.body.style.cursor = 'default'
          }

          if (response.data && response.data.order_number) {
            this.orderCreateSubject.next({success: true})
            this.headerService.stopTimer()
            const payment_method = cart.payment_processor === 'AuthorizeNet' && nonce?.dataDescriptor === 'COMMON.APPLE.INAPP.PAYMENT' ? 'ApplePay' : cart.payment_processor
            let transaction = {
              customer: response.data.customer,
              order_number: response.data.order_number,
              total: Math.round(Number.parseFloat(response.data.total)*100)/100,
              shipping: this.shippingService.getShippingPrice(cart.travelers, 'total'),
              taxes: this.getOrderTotalWithoutFees() - this.getOrderTotalWithoutFees(false),
              shipping_zipcode: cart.shipping?.address?.postal_code || '',
              billing_zipcode: cart.billing?.same_as_shipping ? (cart.shipping?.address?.postal_code || '') : (cart.billing?.address?.postal_code || ''),
              items: [],
              processor: payment_method,
              coupon: cart.promotions ? cart.promotions.promotion_code : ''
            }

            cart.travelers.forEach((traveler) => {
              traveler.products.forEach((product) => {
                this.productService
                  .getProductDetails(traveler.info, product, 'label')
                  .subscribe((label: string) => {
                    let item = {
                      'id': this.productService.getProductDetails(traveler.info, product, 'service_name'),
                      'name': label,
                      'quantity': 1,
                      'price': this.productService.getProductDetails(traveler.info, product, 'subtotal'),
                      'type': product.type,
                      'addons': []
                    }

                    if (product.addons) {
                      product.addons.forEach(addon => {
                        item.addons.push({
                          'quantity': 1,
                          'name': this.productService.getProductDetails(traveler.info, product, 'addon_name', addon),
                          'price': this.productService.getProductDetails(traveler.info, product, 'addon_price', addon)
                        })
                      })
                    }

                    transaction.items.push(item)
                  })
              })
            })

            let no_shipping = cart.travelers.every((traveler) => this.shippingService.checkShipping(traveler))

            this.tagService.orderCreateTags(transaction)

            // Post Message for iFrame
            this.postMessage(response, transaction.total)
            localStorage.removeItem('cart')
            localStorage.removeItem('photo_token')
            localStorage.removeItem('photo_token_expiration')

            let queryParams: any

            if (external) {
              queryParams = {
                order: response.data.order_number,
                email: response.data.email,
                total: response.data.total,
                shipping: !no_shipping,
                external_order: '1',
                external_source: 'officedepot'
              }
            } else {
              queryParams = {
                order: response.data.order_number,
                email: response.data.customer.email,
                token: response.data.order_token,
                uuid: response.data.customer.uuid,
                total: transaction.total,
                shipping: !no_shipping,
              }
            }

            if (response.data.digital_photo) {
              queryParams.digital_photo_app = response.data.digital_photo.app_uuid,
              queryParams.digital_photo_token = response.data.digital_photo.token
            }

            this.router.navigate(['./../step-5'], {
              queryParams
            })
          } else {
            if (cart.payment_processor === 'AmazonPay') {
              this.spinner.hide()
            }
            order_create_subscription.unsubscribe()
            this.orderCreateSubject.next({success: false})

            let data = {
              title: `Credit Card Information Error`,
              message: `Please make sure you entered your credit card information correctly.<br><br>
                  If you continue to experience problems, please call
                  <a href="tel:800-220-1899">(800) 220-1899</a> and we’ll help
                  you complete your order.`,
              icon: `warning`
            }

            if (response.error.status_code == 666) {
              data = {
                title: `Credit Card Declined`,
                message: `There appears to be an issue with the credit card you entered.
                    Please check the card information again or contact your financial instituition.<br><br>
                    If you’re still having issues, try calling customer service at <a href="tel:800-220-1899">(800) 220-1899</a> and we help you complete your order.`,
                icon: `decline`
              }
            }

            this.dialogService.open(
              // Open error dialog if Nonce Failed
              MessageComponent,
              {
                width: 'auto',
                data
              }
            )
          }
        },
        error: error => {
          order_create_subscription.unsubscribe()
          this.orderCreateSubject.next({success: false})
          if (cart.payment_processor === 'AmazonPay') {
            this.spinner.hide()
          }

          if (error.error?.message) {
            this.dialogService.open(
              MessageComponent, {
                width: 'auto',
                data: {
                  title: `Credit Card Information Error`,
                  message: error.error?.message,
                  icon: `warning`
                }
              }
            )
          }
        }
      }
    )
  }

  postMessage(confirmation, total) {
    let message = {
      total: total,
      order_number: confirmation.data.order_number,
      order_uuid: confirmation.data.uuid,
      url: '/step-5'
    }

    let targetWindow = window.parent

    if (targetWindow) {
      targetWindow.postMessage(JSON.stringify(message), '*')
    }
  }

  // --- TOTALS ---

  getBasePrice() {
    const travelers = this.orderForm.get('travelers')
    let total = 0

    travelers.value.forEach((traveler) => {
      traveler.products.forEach((product) => {
        total += this.productService.getProductDetails(traveler.info, product, 'subtotal')
      })
    })
    return total
  }

  getServicePrice(): number {
    const travelers = this.orderForm.get('travelers')
    let total = 0

    travelers.value.forEach((traveler) => {
      traveler.products.forEach((product) => {
        if (product.type === 'passport') {
          total += this.productService.getProductDetails(traveler.info, product, 'service_price_with_insurance_and_card')
        }
      })
    })
    return total
  }

  getShippingPrice() {
    const travelers = this.orderForm.get('travelers')
    let shipping = this.shippingService.shippingForm.value
    let traveler = travelers.value[0]
    let total = 0
    
    if ((shipping.address?.address_1 || this.user) && (shipping.speed  || 
      (traveler.products[0].option_uuid && !this.productService.getProductDetails(traveler.info, traveler.products[0], 'outbound_shipping_required')))) {
        total += this.shippingService.getShippingPrice(travelers.value, 'total')
    }
    return total
  }

  getDiscountTotal(passportProductsOnly = false): number {
    let discount_total = 0

    const travelers = this.orderForm.get('travelers')
    travelers.value.forEach((traveler) => {
      traveler.products.forEach((product) => {
        if (!passportProductsOnly || (passportProductsOnly && product.type === 'passport')) {
          discount_total += this.getProductDiscount(traveler, product)
        }
      })
    })

    return discount_total
  }

  private getTaxesRate(): number {
    return 1 + (this.taxObject?.hst || 0) + (this.taxObject?.qst || 0)
  }

  getRefundGuarantee(): number {
    return (environment?.source?.refund_guarantee && this.hasPassportProducts && this.refundGuarantee) ? (this.getBasePrice() - this.getDiscountTotal(true) + this.getShippingPrice()) * this.REFUND_GUARANTEE_RATE : 0
  }

  getProcessingFeeRate(): number {
    return ((this.payment_methods_config && this.payment_methods_config[this.selectedService$.getValue()]?.processing_fee) || 0) / 100
  }

  /**
   * Includes Subtotal, Shipping, Discount and Taxes if specified. Does not include neither Refund Guarantee nor Convenience Fee.
   */
  getOrderTotalWithoutFees(withTaxes = true): number {
    let total = 0
    total += this.getBasePrice()
    total += this.getShippingPrice()
    total -= this.getDiscountTotal()
    if (withTaxes) {
      total *= this.getTaxesRate()
    }
    return total
  }

  getConvenienceFee(): number {
    return (this.getOrderTotalWithoutFees() + this.getRefundGuarantee()) * this.getProcessingFeeRate()
  }

  /**
   * Includes Base, Shipping, Discount, Taxes, Convenience Fee and Refund Guarantee
   */
  getOrderTotalWithFees(): number {
    return this.getOrderTotalWithoutFees() + this.getConvenienceFee() + this.getRefundGuarantee()
  }

  getProductDiscount(traveler, product) {
    if (this.coupon_code && this.coupon_code.discounts) {
      let discount = this.coupon_code.discounts[0]
      let product_discount = 0

      if (!discount) {
        return 0
      }

      if (discount.conditions) {
        let not_applicable = discount.conditions.some(condition => {
          switch (condition.type) {
            case 'service-type':
              let slug = this.productService.getProductDetails(traveler.info, product, 'service_slug')
              return condition.operator === 'notIn' && condition.value.includes(slug)
          }
        })

        if (not_applicable) return 0
      }

      if (discount.type == 'percent') {
        let cost = this.productService.getProductDetails(traveler.info, product, 'service_cost')
        let discount_amount = cost * this.coupon_code.discounts[0].amount/100

        product_discount = cost < discount_amount ? cost : discount_amount
      } else {
        product_discount = parseFloat(discount.amount)
      }

      return product_discount || 0
    }

    return 0
  }

  // --- END TOTALS ---

  scrollToFirstError() {
    const control = document.querySelector('input.ng-invalid, ' +
      'select.ng-invalid, ' +
      'gw-chkout-order mat-select.ng-invalid,' +
      'mat-radio-group.ng-invalid, ' +
      'mat-checkbox.ng-invalid'
    )

    if (control) {
      control.scrollIntoView({behavior: 'auto', block: 'start', inline: 'nearest'})
      window.scrollBy(0, this.getHeaderHeight())
    } else {
      try {
        this.tagAPIService.reportToPapertrail('CHECKOUT - NO CONTROL FOUND: ', 
          {
            source: environment.source.domain, 
            formValue: this.orderForm.getRawValue()
          })
        .subscribe()
      } catch (e) {}
    }
  }

  getHeaderHeight() {
    const header = document.querySelector('header.gw-chkout__header')
    const height = header ? header.clientHeight : 0
    return (-1 * height) - 30
  }

  /**
   * Determines whether selected service on
   * @returns selected service
   *
   * This implementation must be updated if more applicants are suported and each one has their own service
   */
  onSelectedService(): Observable<any> {
    return this.orderForm.controls.travelers.controls[0].controls.products.controls[0].controls.option_uuid.valueChanges
  }

  checkPromoCode(code: string) {
    let data = {
      promotions: {
        promotion_code: code
      },
      source: environment.source.domain
    }

    this.postRequest(`promotion/apply`, data)
      .subscribe({
        next: (response) => {
          this.couponSubject.next(response)
        },
        error: (error) => {
          this.couponSubject.next(error)
        }
      })
  }

  applyPromoCode(coupon) {
    let coupon_data = {
      promotions: {
        promotion_code: coupon
      },
      source: environment.source.domain
    }

    return this.postRequest(`promotion/apply`, coupon_data)
    .pipe(
      catchError(() => of(null))
    )
  }

  private parsePhone(): void {
    if (environment.support?.phone) {
      let phone_number = parsePhoneNumber(environment.support.phone, 'US')

      this.phone = {
        uri: phone_number.getURI(),
        number: phone_number.formatNational()
      }

      if (environment.source.domain == 'fedex') {
        let number = phone_number.formatInternational()
        number = number.replace('+', '')
        number = number.replace(/\s/g, '.')

        this.phone.number = number
      }
    }
  }

  private getPaymenMethodsConfig() {
    this.getPaymentMethods().subscribe((response) => {
      this.payment_methods_config = response
    })
  }

  public getPurchaseUnits() {
    let cart: any = this.orderForm.value
    let total = this.getOrderTotalWithoutFees().toFixed(2)
    const discounts = this.getDiscountTotal().toFixed(2)
    const full_total = parseFloat(discounts) !== 0
      ? ((parseFloat(total)*100 + parseFloat(discounts)*100)/100).toFixed(2)
      : total

    const items = []

    cart.travelers.forEach((traveler: Traveler) => {
      traveler.products.forEach((product: Product) => {
        if (!items[product.product_uuid + product.option_uuid]) {
          let label
          let service = this.productService.getProductDetails(traveler.info, product, 'service_name')
          let cost = this.productService.getProductDetails(traveler.info, product, 'service_cost')

          this.productService.getProductDetails(traveler.info, product, 'label')
              .subscribe((value: string) => { label = value })

          // include the gov_fee for visas
          let gov_fee: any = false
          if (product.type === 'visa') {
            gov_fee = this.productService.getProductDetails(traveler.info, product, 'gov_fee')
            if (gov_fee) {
              cost = (parseInt(cost) + parseInt(gov_fee)).toFixed(2)
            }
          }

          items[product.product_uuid + product.option_uuid] = {
            name: `${label} Application ${service} ${gov_fee ? '+Gov. Fee' : ''}`,
            quantity: '1',
            category: 'DIGITAL_GOODS',
            unit_amount: {
              currency_code: 'USD',
              value: cost,
            },
          }
        } else {
          const add = Number(items[product.product_uuid + product.option_uuid].quantity) + 1

          items[product.product_uuid + product.option_uuid].quantity = add.toString()
        }

        // add addons
        for (const addon of product.addons) {
          if (!items[addon]) {
            items[addon] = {
              name: this.productService.getProductDetails(traveler.info, product, 'addon_name', addon),
              quantity: '1',
              category: 'DIGITAL_GOODS',
              unit_amount: {
                currency_code: 'USD',
                value: this.productService.getProductDetails(traveler.info, product, 'addon_price', addon)
              }
            }
          } else {
            const add = Number(items[addon].quantity) + 1
            items[addon].quantity = add.toString()
          }
        }
      })
    })

    // add inbound_shipping
    if (!items['inbound_shipping']) {
      items['inbound_shipping'] = {
        name: 'Inbound Shipping',
        quantity: '1',
        category: 'DIGITAL_GOODS',
        unit_amount: {
          currency_code: 'USD',
          value: this.shippingService.getShippingPrice(cart.travelers, 'inbound')
        }
      }
    } else {
      const add = Number(items['inbound_shipping'].quantity) + 1
      items['inbound_shipping'].quantity = add.toString()
    }

    // add outbound_shipping
    if (!items['outbound_shipping']) {
      items['outbound_shipping'] = {
        name: 'Outbound Shipping',
        quantity: '1',
        category: 'DIGITAL_GOODS',
        unit_amount: {
          currency_code: 'USD',
          value: this.shippingService.getShippingPrice(cart.travelers, 'outbound')
        },
      }
    } else {
      const add = Number(items['outbound_shipping'].quantity) + 1
      items['outbound_shipping'].quantity = add.toString()
    }

    const result = {
      amount: {
        currency_code: 'USD',
        value: total,
        breakdown: {
          item_total: {
            currency_code: 'USD',
            value: full_total
          },
        },
      },
      items: _.values(items)
    }

    // add the discount
    if (parseFloat(discounts) !== 0) {
      result.amount.breakdown['discount'] = {
        currency_code: 'USD',
        value: discounts
      }
    }
    return result
  }

  public logInvalid(data: any) {
    return this.postRequest(`report/logging/ops`, data)
  }

  public getTaxValue(state: string) {
    return this.getRequest(`product/canada/${state}/tax`)
      .subscribe({
        next: (response) => {
          this.taxObject = {
            hst: response.hst
          }

          if (response.qst) {
            this.taxObject.qst = response.qst
          }

          this.taxSubject.next(this.taxObject)
        },
        error: (error) => {
        }
      })
  }

  get refundGuarantee(): boolean {
    return this.orderForm?.value.refund_guarantee
  }

  /**
   * Determines whether any product is of type passport
   * @returns  true if contains at least one product of type passport, false otherwise
   */
  get hasPassportProducts(): boolean {
    const travelersCtrl = this.orderForm.get('travelers')
    for (const traveler of travelersCtrl.value) {
      for (const product of traveler.products) {
        // Only American passports. Add  || product.type === 'ca_passport' for Canadian passports too
        if (product.type === 'passport') {
          return true
        }
      }
    }
    return false
  }
}
