import { Component, Input, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { PaypalService } from 'src/app/services/paypal.service'

@Component({
	selector: 'gwc-paypal',
	templateUrl: './paypal.component.html',
	styleUrls: ['./paypal.component.scss'],
	standalone: true,
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class PayPalComponent {
	@Input() type: string

	constructor(
		private paypalService: PaypalService
	) {}

	ngAfterViewInit() {
		this.renderButton()
	}

	private renderButton() {
		const button = this.paypalService[this.type === 'paypal' ? 'payPalButton' : 'venmoButton']

		console.log(button)
		if (button) {
			const element = document.getElementById(`${this.type}ButtonContainer`)

			console.log(element)
			if (element) {
				button.render(element)
			}
		}
	}
}
