<div class="gw-chkout-main gw-chkout-payment"
  [ngClass]="{'gw-chkout-payment--photo': isRMPhotoEnv,
  'gw-chkout-elevation': isRMPhotoEnv}">
  @if (isRMPhotoEnv) {
    <div [ngClass]="{'gw-chkout-heading--pickup': isPickupOptSelected}">
      <h3>
        {{ heading }}
      </h3>
      @if (isPickupOptSelected) {
        <a href="/photo/step-3">Change Location</a>
      }
    </div>
  } @else {
    <h3 class="gw-chkout-label">
      Payment Information
    </h3>
  }
  @if (!isPickupOptSelected) {
    <mat-radio-group color="primary"
      aria-label="Select Expediting Options"
      [(ngModel)]="selected_service"
      class="gw-checkout-radio">
      <mat-accordion>
        @for (option of payment_methods; track option) {
          <mat-radio-button
            class="gw-chkout-radio__item gw-chkout-payment__method"
            [class.gw-chkout-payment__method--single]="payment_methods.length == 1"
            [disableRipple]="option === selected_service"
            [checked]="option === selected_service"
            [disabled]="option === selected_service"
            (change)="paymentMethodChanged($event)"
            [value]="option">
            <mat-expansion-panel hideToggle
              [disabled]="true"
              [expanded]="option === selected_service"
              class="gw-chkout-payment__method-body mat-elevation-z">
              <mat-expansion-panel-header>
                <mat-panel-title class="gw-chkout-payment__option">
                  <span class="gw-chkout-payment__option-label">{{ option.replace('_', ' ') }}</span>
                  <span class="gw-chkout-payment__option-image gw-chkout-payment__option-image--{{option}}">
                    <img src="assets/svg/{{ option }}.svg" alt="image description">
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <!--credit card-->
              @if (option == 'credit_card') {
                <section>
                  <gwc-credit-card [ccForm]="payment"
                    [billingAddressForm]="billing_address"
                    [shippingAddressAvailable]="shippingAddressAvailable"
                    [state]="shippingAddressState"
                    [country]="shippingAddressCountry">
                  </gwc-credit-card>
                </section>
              } @else if (option === 'paypal') {
                <section>
                  <p>By authorizing the <span class="pay-system-name">PayPal</span> payment of <strong>{{ getOrderTotal() | currency:currency:'symbol':'1.2-2' }}</strong>, I am acknowledging that I have reviewed and agree with the
                  <gwc-legal-link type="terms"></gwc-legal-link>
                  and
                  <gwc-legal-link type="privacy"></gwc-legal-link>.
                  Click PayPal button to proceed</p>
                  <gwc-paypal type="paypal"
                  ></gwc-paypal>
                </section>
              } @else if (option === 'venmo') {
                <section>
                  <p>By authorizing the <span class="pay-system-name">Venmo</span> payment of <strong>{{ getOrderTotal() | currency:currency:'symbol':'1.2-2' }}</strong>, I am acknowledging that I have reviewed and agree with the
                  <gwc-legal-link type="terms"></gwc-legal-link>
                  and
                  <gwc-legal-link type="privacy"></gwc-legal-link>.
                  Click Venmo button to proceed</p>
                  <gwc-paypal type="venmo"
                  ></gwc-paypal>
                </section>
              }@else if (option === 'amazon') {
                <section>
                  <p>Your <span class="pay-system-name">Amazon</span> account will be charged <strong>{{ getOrderTotal() | currency:currency:'symbol':'1.2-2' }}</strong> for this order.</p>
                  @if (amazonService.amazonConfig !== null) {
                    <app-amazon-pay [config]="amazonService.amazonConfig"
                      [credentials]="orderService.payment_methods_config.amazon.credentials">
                    </app-amazon-pay>
                  } @else {
                    <div style="text-align: center; font-weight: bold; text-transform: uppercase">
                      Loading... {{ this.amazonService.amazonConfig }}
                    </div>
                  }
                </section>
              } @else if (option === 'apple') {
                <section>
                  <p>
                    By authorizing the <span class="pay-system-name">ApplePay</span> payment of <strong>{{ getOrderTotal() | currency:currency:'symbol':'1.2-2' }}</strong>, I am acknowledging that I have reviewed and agree with the
                    <gwc-legal-link type="terms"></gwc-legal-link>
                    and
                    <gwc-legal-link type="privacy"></gwc-legal-link>.
                  </p>
                  <gwc-apple-pay [currency]="currency"
                    [total]="getOrderTotal()"
                    [domain]="domain">
                  </gwc-apple-pay>
                </section>
              }
            </mat-expansion-panel>
          </mat-radio-button>
        }
      </mat-accordion>
    </mat-radio-group>
  } @else {
    <!-- Office Depot -->
     <div class="gw-chkout-office-depot">
        <div class="gw-chkout-office-depot__info">
          <h4>Office Depot</h4>
          <div>
            <p>{{ selectedFacility?.address?.address_1 }}, {{ selectedFacility?.address?.city }}, {{ selectedFacility?.address?.state }}, {{ selectedFacility?.address?.zip }} </p>
            <p> {{ selectedFacility?.phone }} </p>
          </div>
          <div class="gw-chkout-office-depot__hours">
            <h5>Hours:</h5>
            <p>Mon: {{ selectedFacility?.hours?.m?.open }} - {{ selectedFacility?.hours?.m?.close }}</p>
            <p>Tue: {{ selectedFacility?.hours?.t?.open }} - {{ selectedFacility?.hours?.t?.close }}</p>
            <p>Wed: {{ selectedFacility?.hours?.w?.open }} - {{ selectedFacility?.hours?.w?.close }}</p>
            <p>Thu: {{ selectedFacility?.hours?.t?.open }} - {{ selectedFacility?.hours?.t?.close }}</p>
            <p>Fri: {{ selectedFacility?.hours?.f?.open }} - {{ selectedFacility?.hours?.f?.close }}</p>
            <p>Sat: {{ selectedFacility?.hours?.s?.open }} - {{ selectedFacility?.hours?.s?.close }}</p>
            <p>Sun: {{ selectedFacility?.hours?.su?.open }} - {{ selectedFacility?.hours?.su?.close }}</p>
          </div>
        </div>
        <div class="gw-chkout-office-depot__map">
          <iframe [src]="mapURL"
            width="100%"
            [height]="400"
            style="border: 0; border-radius: 25px; background: #F5F5F5;"
            loading="lazy"
            allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
     </div>
  }
</div>

@if (!isPickupOptSelected && isRefundGuaranteeAvailable && hasPassportProducts()) {
  <gwc-refund [orderForm]="orderForm"></gwc-refund>
}

<gwc-disclaimer [ccForm]="payment"
  [total]="getOrderTotal()"
  [submitted]="submitted"
  [isPickupOptSelected]="isPickupOptSelected">
</gwc-disclaimer>
<ngx-spinner bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Processing...</p>
</ngx-spinner>
