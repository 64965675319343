<div class="gw-chkout-visa-opt"
	[class.gw-chkout-visa-opt--first]="idx === 0">
	<span class="gw-chkout-visa-opt__label">
		<strong>{{ service.name }}</strong>
	</span>
	<span>
		{{ beautifyRange(service.min_interval, service.max_interval) }}
	</span>
	<span class="gw-chkout-visa-opt__fee">
		${{ service.gov_fee }}
		<span class="gw-chkout-visa-opt__label--mob-only">Consulate fee</span>
	</span>
	<span class="gw-chkout-visa-opt__fee gw-chkout-visa-opt__fee--service">
		${{ service.default_price }}
		<span class="gw-chkout-visa-opt__label--mob-only">Service fee</span>
	</span>
</div>
