export const environment: any = {
  "production": true,
  "API": "https://api.rushmypassport.com/api/",
  "visas": true,
  "header" : {
    "logo": "https://govworks-email-assets.s3.amazonaws.com/fedex_office.svg"
  },
  "support": {
    "phone": "8002201899",
    "email": "info@rushmypassport.com"
  },
  "login": {
    "enabled": false,
    "logo":"assets/svg/rmp_logo.svg"
  },
  "fedex_logo": false,
  "legal_links": {
    "terms": "https://www.rushmypassport.com/fedex-terms-and-conditions/",
    "privacy": "https://www.rushmypassport.com/fedex-privacy-policy/",
    "refund": "https://www.rushmypassport.com/fedex-refund-policy/"
  },
  "source": {
    "domain": "fedex",
    "main_website": "https://www.office.fedex.com/default/passport-services",
    "promo_code": true,
    "name": "FedEx"
  },
  "footer": {
    "logo": "assets/svg/rmp_logo.svg",
    "logo_link": "/",
    "copyright": "RushMyPassport.com, LLC"
  },
  "create_lead": true, 
  "seals": {
    "mcafee": "https://www.scanalert.com/RatingVerify?ref=www.rushmypassport.com",
    "truste": "170aae01-cd7c-4ad6-9007-5894464c0962"
  },
  "summary_cta_icon": false,
  "questions": {
    "passport": [
      {
        "id": "renew",
        "title": "How do I renew my passport?",
        "description": "If you have an adult passport (issued when the applicant was 16 or older with a ten-year validity) - and your passport is less than 5 years expired, you can renew your adult issued passport. For expedited renewal services, bring your expired or soon-to-expire passport, credit card and checkbook to your local FedEx Office. FedEx Office has partnered with RushMyPassport to offer a wide range of passport renewal processing speeds."
      },
      {
        "id": "get-passport",
        "title": "What do I need to get a passport?",
        "description": "The required documents depend on the passport type you need. Please see below for specific types and documents required.<br><br><strong>For an Adult Passport Renewal</strong>, you can simply visit your local FedEx Office and bring your expired or soon-to-expire passport and check payment for the government fee. We can help prepare your new passport photo and the required application forms.<br><br><strong>For a New Adult Passport, Child Passport, and a Replacement Passport</strong>, you will need to bring in additional documentation including: proof of identity (Driver’s License or State Identification Card), proof of citizenship (Birth Certificate or Naturalization Paperwork). For a child passport, you must provide proof of parental relationship (Parent’s Photo ID’s that must match child’s birth certificate).<br><br><strong>Please note:</strong> requesting one of these passport options requires the applicant to appear in front of a Passport Acceptance Agent to have the documents adjudicated. This person is often a postal employee or an official at your local county clerk office. The adjudication process is an additional layer of security to ensure that the applicant is the person at this visit and that the documents have been reviewed and sealed to ensure appropriate chain of custody once it is received by RushMyPassport.<br><br>You can find your nearest passport acceptance agent <a target=\"_blank\" href=\"https://iafdb.travel.state.gov/\">here</a>."
      },
      {
        "id": "guaranteed",
        "title": "Am I guaranteed to get a passport if I use this service?",
        "description": "We guarantee that we will submit your application for expedited processing. However there are circumstances out of our control which can delay, suspend or deny your application.<br><br>Examples, other than weather, include owing child support or back taxes, having travel restrictions due to a felony, or not having the necessary identification.<br><br>When you use our service, we are notified immediately and can work with you to remedy the issue timely."
      },
      {
        "id": "passport-cost",
        "title": "How much does it cost to expedite a passport?",
        "description": "<strong>The government fee is an additional $190 ($130 + $60) for adults and $160 ($100 + $60) for children under 16. It is not included in the expediting service cost</strong>, is paid via personal check or money order directly to the US Department of State, and is included with your application when shipping to RushMyPassport.<br><br>If you wish to add a passport card, there is an additional fee of $30 for adults or $15 for children under 16."
      },
      {
        "id": "name-change",
        "title": "What if my name is different from what is listed on my passport?",
        "description": "If your name is different from what is listed on your birth certificate or has changed since the issuance of your most recent passport,  you can still renew your passport. When completing your passport application,  use your current legal name.  In addition, you will need to provide the original name change document such as a marriage certificate, divorcee decree or adoption paperwork."
      },
      {
        "id": "benefits",
        "title": "What is the benefit of using this service?",
        "description": "The benefit of using a third party hand courier includes:<br/><ul><li>Time and Convenience.  We save you time and a trip to the passport office.</li><li>We help avoid 30% of delays by carefully reviewing your application for errors or omissions before submission.</li><li>If there is an issue with your application we are notified by the passport office so we can fix any issues quickly.</li><li>We provide smart-form automation, easy to use checklists and in-depth quality assurance.</li><li>Tracking of your documents from start to finish.</li><li>Customer support by phone, chat and email. We take the worry out of the process and allow you to focus on fun and adventure.</li></ul>"
      },
      {
        "id": "6-month-validity",
        "title": "What is the 6-month validity rule?",
        "description": "Most countries require your US passport to have a minimum of 6-months validity after your intended return date.  Please review your expiration date to see if a renewal is needed.   The purpose of this validity is to safeguard against unforeseen delays you may face in leaving the country you are visiting. The State Department recommends that U.S. citizens renew their passports approximately nine months before the date of expiration."
      },
      {
        "id": "renewal-eligible",
        "title": "How do I know if my passport is eligible to be renewed?",
        "description": "You are eligible for a passport renewal if ALL of the following are true:<br/><ul><li>Your passport is currently valid, or has expired within the last five years.</li><li>Your passport is in your possession.</li><li>Your passport is not damaged.</li><li>Your passport was issued when you were age 16 or older.</li></ul>"
      },
      {
        "id": "renew-child",
        "title": "How do I renew my child’s passport?",
        "description": "A child passport cannot be renewed. It is considered a new child passport each time until the applicant is 16 or older.  At that time, the applicant can apply for a new adult passport. Please note that a visit to a Passport Acceptance facility is required for all child passports."
      },
      {
        "id": "fedex-aa",
        "title": "Is FedEx Office a passport acceptance facility?",
        "description": "No. FedEx Office is not a Passport Acceptance facility.  An Acceptance Facility is usually located within the county clerk office or US Post Office.  A separate fee of $35 is collected at this visit.<br><br>You can find your nearest passport acceptance agent <a target=\"_blank\" href=\"https://iafdb.travel.state.gov/\">here</a>."
      },
      {
        "id": "replace-lost",
        "title": "How do I replace a lost passport?",
        "description": "In order to replace a lost passport, the applicant will need to gather the required documents including proof of citizenship (birth certificate or naturalization paperwork) and proof of identity (Driver’s License of State Issued Identification) A visit to the Passport Acceptance facility is required when replacing a lost or stolen passport. A separate fee of $35 is collected at this visit."
      }
    ]
  },
  "slides": {
    "passport": [
      {
        "title": "Select Service Type",
        "icon": {
          "name": "gw-slider-mouse",
          "width": "45px",
          "height": "88px"
        },
        "elements": [
          "Select the type of passport service you need, and indicate how quickly you need it.",
          "<strong>Expediting cost does not include shipping fees or government fees</strong>, which are paid separately to the U.S. Department of State (<strong>starting at $190</strong> for adults and <strong>$160</strong> for children)."
        ]
      },
      {
        "title": "Complete Online Wizard",
        "icon": {
          "name": "gw-slider-papers",
          "width": "69px",
          "height": "92px"
        },
        "elements": [
          "After selecting expediting options, you will be directed to create a govWorks account to complete your passport forms.",
          "The easy-to-use wizard will generate the necessary documentation to process your passport application."
        ]
      },
      {
        "title": "Get Your Passport Photo at FedEx Office",
        "icon": {
          "name": "gw-slider-user",
          "width": "97px",
          "height": "97px"
        },
        "elements": [
          "Every passport application requires a printed government-compliant photo be submitted with your application.",
          "See a team member at FedEx Office to purchase your passport photo.",
          "<a href='https://local.fedex.com/en/passport-renewal' target='_blank'>Find a FedEx Office near you.</a>"
        ]
      },
      {
        "title": "Gather Documents and Ship",
        "icon": {
          "name": "gw-slider-fedex",
          "width": "109px",
          "height": "64px"
        },
        "elements": [
          "Review your checklist to ensure you have all required documents.",
          "Follow the instructions for shipping your documents.",
          "Please note: Some services will not require a FedEx shipping label and are shipped via the Passport Acceptance Agent."
        ]
      }
    ],
    "visa": [
      {
        "title": "Select Country and Service Type",
        "icon": {
          "name": "gw-slider-mouse",
          "width": "45px",
          "height": "88px"
        },
        "elements": [
          "Confirm your Nationality and state of residence.",
          "Select the travel Destination (Country) and Visa type.",
          "Expediting processing speeds range depending upon the visa type and country to be visited.",
          "Expediting Cost does not include shipping fees or government fees."
        ]
      },
      {
        "title": "Important Instructions",
        "icon": {
          "name": "gw-slider-papers",
          "width": "69px",
          "height": "92px"
        },
        "elements": [
          "Once your order has been placed, you will receive an itemized list of requirements for your visa request.",
          "Please complete in full the visa application and gather any supporting documents needed for the request."
        ]
      },
      {
        "title": "Get Your Photo at FedEx Office",
        "icon": {
          "name": "gw-slider-user",
          "width": "97px",
          "height": "97px"
        },
        "elements": [
          "Every application requires a printed government-compliant photo be submitted with your application.",
          "See a team member at FedEx Office to purchase your photo.",
          "<a href='https://local.fedex.com/?promo=passport' target='_blank'>Find a FedEx Office near you.</a>"
        ]
      },
      {
        "title": "Gather Documents and Ship Them via FedEx",
        "icon": {
          "name": "gw-slider-fedex",
          "width": "109px",
          "height": "64px"
        },
        "elements": [
          "Review your checklist to ensure you have all the required documentation (visa application, passport photos, travel itinerary, passport with minimum 6 months of validity).",
          "Use the FedEx shipping label to ship your application to our office, where your documents will be reviewed and submitted to the Embassy/Consulate for processing.",
          "E-visas are handled online and do not require shipping."
        ]
      }
    ]
  },
  "client_url": "https://my.govworks.com",  
  "landing_pages":  {
    "root_type": "passport",
    "passport": {
      "enabled": true,
      "title": "FedEx Office | Passport Expediting",
      "compact_signin": true, 
      "signin": "Returning customer? Sign in to our secure portal to check your application.",
      "sign_in_links": ["visas"],
      "heading": "Passports With FedEx Office.",
      "description": "Traveling soon and need a passport? Begin your passport application now through FedEx Office."
    },
    "visa": {
      "enabled": true,
      "title": "FedEx Office | Visa Expediting",
      "compact_signin": true, 
      "signin": "Returning customer? Sign in to our secure portal to check your application.",
      "sign_in_links": ["passport"],
      "heading": "Hassle-free expedited travel visa services",
      "description": "Business visa, tourist visa, and everything in between. Our visa experts across the country handle your travel visa requests - quickly, securely, and trouble-free." 
    }
  }, 
  "locations_page": {
    "enabled": true,
    "title": "FedEx Office | Locations"
  },
  "livechat": {
    "enabled": true,
    "license_id": "1073051",
    "group": 12
  },
  "tags": {
    "adwords": "AW-703393275",
    "gtag_conv": "8wi8CIqAj7MBEPvbs88C",
    "mouseflow": "752d6916-3f11-4bab-af5f-f9e67956f3ef",
    "bing": "56102814",
    "steelhouse": 32094,
    "tag_manager": "GTM-NVT6M5N",
    "ga4": ["G-5S9MVF86SJ", "G-0EZ3YBN1JR"]
  }
};