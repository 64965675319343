import { Injectable } from '@angular/core'
import { ApiService } from './api.service'

@Injectable({
	providedIn: 'root'
})

export class TagAPIService extends ApiService {
	public reportToPapertrail(key: string, value: any) {
		return this.postRequest(`report/logging/event`, {
			key,
			value: JSON.stringify(value)
		})
	}
}
