<form [formGroup]="militaryAddressForm()"
	class="gw-military-address__form">
	<div class="gw-chkout-form">
		<mat-form-field class="gw-chkout-field"
			[hideRequiredMarker]="true">
			<mat-label>Address</mat-label>
				<input matInput
					formControlName="address">
			<mat-hint>UNIT, CMR, or PSC number</mat-hint>
			<mat-error>Please enter a valid address.</mat-error>
		</mat-form-field>

		<mat-form-field class="gw-chkout-field"
			[hideRequiredMarker]="true">
			<mat-label>Box Number</mat-label>
				<input matInput
					formControlName="box">
			<mat-error>Please enter a valid box number.</mat-error>
		</mat-form-field>
	</div>

	<div class="gw-chkout-form">
		<mat-form-field class="gw-chkout-field"
			[hideRequiredMarker]="true">
			<mat-label>Location Type</mat-label>
			<mat-select formControlName="city">
				@for (type of LOCATION_TYPES; track type.value) {
					<mat-option [value]="type.value">
						{{ type.label }}
					</mat-option>
				}
			</mat-select>
			<mat-error>Please select a location type.</mat-error>
		</mat-form-field>

		<mat-form-field class="gw-chkout-field"
			[hideRequiredMarker]="true">
			<mat-label>Base Location</mat-label>
			<mat-select formControlName="base_location">
				@for (location of BASE_LOCATIONS; track location.value) {
					<mat-option [value]="location.value">
						{{ location.label }}
					</mat-option>
				}
			</mat-select>
			<mat-error>Please select a base location.</mat-error>
		</mat-form-field>

		<mat-form-field class="gw-chkout-field"
			[hideRequiredMarker]="true">
			<mat-label>Zip Code</mat-label>
				<input matInput
					formControlName="postal_code"
					pattern="^\d{5}$">
				<mat-error>Please provide a valid zip code.</mat-error>
		</mat-form-field>
	</div>
</form>
