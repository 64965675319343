import { Component, Signal } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { MatGridListModule } from '@angular/material/grid-list'
import { ActivatedRoute } from '@angular/router'
import { map } from 'rxjs'
import { TrustpilotModule } from 'src/app/components/trustpilot/trustpilot.module'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-terms',
	templateUrl: './terms.component.html',
	styleUrls: ['./terms.component.scss'],
	standalone: true,
	imports: [
		MatGridListModule,
		TrustpilotModule
	]
})

export class TermsComponent {
	protected readonly titles: {[key: string]: string} = {
		terms: 'Terms of Use',
		privacy: 'Privacy Policy',
		refund: 'Refund Policy'
	}
	protected readonly website_url: string = environment.terms?.website_url || 'https://www.rushmypassport.com/'
	protected readonly website: string = environment.terms?.website || 'www.rushmypassport.com'
	protected readonly name: string = environment.terms?.website_name || 'RushMyPassport.com'
	protected readonly email: string = environment.terms?.website_email || 'info@rushmypassport.com'
	protected readonly trustpilot = environment.tags?.trustpilot || null
	protected readonly domain: string = environment.source.domain
	protected readonly country: string = environment.terms?.country || 'US'
	public type: Signal<string> = toSignal(this.activatedRoute.data.pipe(
		map(response => response.type)
	))

	constructor(
		private activatedRoute: ActivatedRoute
	){}
}
