<aside class="gw-chkout-summary"
  [ngClass]="{'gw-chkout-summary--photo': isRMPhotoEnv,
  'gw-chkout-elevation': isRMPhotoEnv && !isStepThreeOrFour(),
  'lastSteps': isStepThreeOrFour()}">
  @if (!benefits) {
    <div class="gw-chkout-summary__body">
      <h4 class="gw-chkout-summary__label">
        Order summary
      </h4>
      @for (traveler of travelers.controls; track traveler; let traveler_index = $index) {
        <mat-expansion-panel
          class="gw-chkout-summary__traveler"
          [expanded]="travelers.controls.length == 1"
          [disabled]="travelers.controls.length == 1"
          hideToggle="true">
          @if (!isRMPhotoEnv) {
            <mat-expansion-panel-header class="gw-chkout-summary__traveler-header">
              <div class="gw-chkout-summary__traveler-name"
                [class.gw-chkout-summary__traveler--collapsable]="travelers.controls.length > 1">
                <span>
                  @if (traveler.value.info.first_name && traveler.value.info.last_name) {
                    {{ traveler.value.info.first_name }} {{ traveler.value.info.last_name }}
                  } @else {
                    Applicant {{ traveler_index + 1 }}
                  }
                </span>
              </div>
              <span class="gw-chkout-summary__traveler-subtotal">
                $403.90
              </span>
            </mat-expansion-panel-header>
          }
          <div class="gw-chkout-summary__wrap">
            @for (product of traveler.value.products; track product) {
              <div class="gw-chkout-summary__service">
                <section>
                  <span class="gw-chkout-summary__highlight gw-chkout-summary__item">
                    <strong>
                      @if (product.product_uuid) {
                          {{ getProductDetails(traveler, product, 'label') | async }} {{ ['digital-photo', 'idp'].includes(product.type) ? '' : 'Application' }}
                      } @else {
                        @switch (product.type) {
                          @case ('passport') {
                            U.S. Passport Application
                          }
                          @case ('ca_passport') {
                            Canadian Passport Application
                          }
                        }
                      }
                    </strong>
                  </span>
                  <ul>
                    @if (product.option_uuid) {
                      <section>
                        <li>
                          <span>{{ getProductDetails(traveler, product, 'service_name') }}</span>
                          <span>{{ getProductDetails(traveler, product, 'service_cost') | currency:currency:'symbol':'1.2-2' }}</span>
                        </li>
                        @if (product.type == 'visa') {
                          <li>
                            <span>Government Fee</span>
                            <span>{{ getProductDetails(traveler, product, 'gov_fee') | currency:currency:'symbol':'1.2-2' }}</span>
                          </li>
                        }
                      </section>
                      <section>
                        @for (addon of product.addons; track addon) {
                          <li>
                            <span>{{ getProductDetails(traveler, product, 'addon_name', addon) }}</span>
                            <span>{{ getProductDetails(traveler, product, 'addon_price', addon) | currency:currency:'symbol':'1.2-2' }}</span>
                          </li>
                        }
                      </section>
                    } @else {
                      <li>
                        <span>No Service Selected...</span>
                        <span>$0.00</span>
                      </li>
                    }
                  </ul>
                  @if (product.option_uuid) {
                    <div class="gw-chkout-summary__subtotal">
                      <span>Subtotal</span>
                      <span>{{ getProductDetails(traveler, product, 'subtotal') | currency:currency:'symbol':'1.2-2' }}</span>
                    </div>
                  }
                </section>
                @if (discount) {
                  <div class="gw-chkout-summary__subtotal gw-chkout-summary__coupon">
                    <span>{{ discount.name }}</span>
                    <span>
                      {{ getProductDiscount(traveler, product) === 0 ? '-' : '' }}{{ getProductDiscount(traveler, product) | currency:currency:'symbol':'1.2-2' }}
                    </span>
                    @if (getProductDiscount(traveler, product) == 0 && product.option_uuid) {
                      <div class="gw-chkout-summary__coupon-warning">
                        Not applicable for the selected service
                      </div>
                    }
                  </div>
                }
              </div>
            }
            @if (getShippingPrices('total') > 0 && shipping?.controls?.address?.controls?.address_1?.value) {
              <div class="gw-chkout-summary__service">
                <ul>
                  @if (getShippingPrices('inbound') > 0) {
                    <li>
                      <span>Inbound Shipping</span>
                      <span>{{ getShippingPrices('inbound') | currency:currency:'symbol':'1.2-2' }}</span>
                    </li>
                  }
                  @if (shipping.value.speed) {
                    <li>
                      <span>Outbound Shipping</span>
                      <span>{{ getShippingPrices('outbound') | currency:currency:'symbol':'1.2-2' }}</span>
                    </li>
                  }
                </ul>
                <div class="gw-chkout-summary__subtotal">
                  <span>Shipping Total</span>
                  <span>{{ getShippingPrices('total') | currency:currency:'symbol':'1.2-2' }}</span>
                </div>
              </div>
            }
            @if (tax) {
              <div class="gw-chkout-summary__service">
                <ul>
                  @for (type of tax | keyvalue; track type) {
                    <li>
                      <span>{{ type.key.toUpperCase() }}({{ type.value * 100 | number: '1.0-3' }}%)</span>
                      <span>{{ orderService?.getOrderTotalWithoutFees(false) * type.value | currency:currency:'symbol':'1.2-2' }}</span>
                    </li>
                  }
                </ul>
              </div>
            }
            @if (step === 3 && ((isRefundGuaranteeAvailable && hasPassportProducts && refundGuarantee) || orderService?.getProcessingFeeRate() > 0)) {
              <div class="gw-chkout-summary__service">
                <ul>
                  @if (isRefundGuaranteeAvailable && hasPassportProducts && refundGuarantee) {
                    <li>
                      <span>Refund Guarantee</span>
                      <span>{{ orderService?.getRefundGuarantee() | currency:currency:'symbol':'1.2-2' }}</span>
                    </li>
                  }
                  @if (orderService?.getProcessingFeeRate() > 0) {
                    <li>
                      <span>Convenience Fee</span>
                      <span>{{ orderService?.getConvenienceFee() | currency:currency:'symbol':'1.2-2' }}</span>
                    </li>
                  }
                </ul>
              </div>
            }
          </div>
          <div class="gw-chkout-summary__wrap gw-chkout-summary__total"
            [ngClass]="{'gw-chkout-summary__total--pb-0': odPrintLocationCtrl}">
            <strong>Total</strong>
            <span>
              {{ getOrderTotal() | currency:currency:'symbol':'1.2-2' }}
            </span>
            @if (odPrintLocationCtrl) {
              <p class="gw-chkout-summary__total-note">To be paid at pick-up.</p>
            }
            @if (checkIfPassportOrder()) {
              @if (domain !== 'aarp') {
                <small class="gw-chkout-summary__notice">
                  {{ checkIfGovFeeIncluded() ? 'Including ' : 'Not including ' }}
                  @if (getGovFee(traveler) > 0) {
                    <strong>${{ getGovFee(traveler) }}</strong>
                  }
                  government fee.
                </small>
              } @else {
                @if (checkIfGovFeeIncluded()) {
                  <small
                    class="gw-chkout-summary__notice">
                    Includes all U.S. Government and shipping fees.
                  </small>
                } @else {
                  <small class="gw-chkout-summary__notice">
                    Not including <strong>${{ getGovFee(traveler) }}</strong> government fee.
                  </small>
                }
              }
            }
          </div>
        </mat-expansion-panel>
      }
      @if (promo_code) {
        <mat-expansion-panel
          hideToggle="true"
          [expanded]="promo_open"
          (opened)="promo_open = true"
          class="gw-chkout-summary__promo"
          #promoDrop>
          <mat-expansion-panel-header [expandedHeight]="'0px'"
            [collapsedHeight]="'38px'">
            <a>Have a promo code?</a>
          </mat-expansion-panel-header>
          <form [formGroup]="promoForm"
            #formDirective="ngForm"
            (submit)="checkPromoCode()">
            <mat-form-field class="gw-chkout-field"
              [hideRequiredMarker]="true">
              <mat-label>Promo Code</mat-label>
              <input matInput
                required
                formControlName="promoCode">
              <mat-error>
                Invalid code or already redeemed
              </mat-error>
            </mat-form-field>
            <button mat-button
              type="submit"
              [disabled]="checking_promo"
              class="gw-chkout-grey-btn">
              @if (!checking_promo) {
                <span>Apply</span>
              } @else {
                <mat-spinner class="gw-checkout-button__load"
                  [diameter]="20"></mat-spinner>
              }
            </button>
          </form>
        </mat-expansion-panel>
      }
    </div>
    <div class="gw-chkout-summary__cta"
      [class.gw-chkout-summary__cta--error]="form_error">
      <button mat-flat-button
        (click)="submitForm()"
        [disabled]="submitting()"
        color="primary"
        class="gw-chkout-main-btn gw-chkout-main-btn--full gw-chkout-summary__cta-button"
        [ngClass]="{
          'gw-chkout-summary__cta-button--icon': !isRMPhotoEnv && summary_cta_icon && step != 3,
          'gw-chkout-summary__cta-button--submit': !isRMPhotoEnv && summary_cta_icon && step == 3,
          'gw-chkout-btn--rounded': isRMPhotoEnv
        }">
        @if (!submitting()) {
          <span>
            @if (step === 2) {
              {{ isRMPhotoEnv ? 'Review Order' : 'Review Your Order'}}
            } @else if (step === 3) {
              Submit Your Order
            } @else {
              Continue
            }
          </span>
        } @else {
          <mat-spinner class="gwc-button__spinner"
            [diameter]="20">
          </mat-spinner>
        }
      </button>
      @if (form_error) {
        <div class="gw-chkout-summary__error">
          Please review the highlighted items before continuing.
        </div>
      }
    </div>
  } @else {
    <div class="gw-chkout-summary__benefits">
      <ul>
        @for (benefit of benefits_list; track benefit; let i = $index) {
          <li>
            <span>{{ isRMPhotoEnv ? '&#x2713;' : i + 1}}</span>
            <h2>
              {{ benefit.label }}
            </h2>
            <p>
              {{ benefit.description }}
            </p>
          </li>
        }
      </ul>
    </div>
  }
</aside>
