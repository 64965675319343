@if (product_type === 'passport') {
  @if (!gov_fee_included) {
    <span class="gwc-gov-fee__fee">
      *Government fee of ${{ gov_fee }} to be paid to the "U.S. Department of State" is {{ !gov_fee_included ? 'not':'' }} included.
    </span>
  
    @if (message) {
      <div class="gwc-gov-fee__message"
        [innerHtml]="message">
      </div>
    }
  } @else if (domain === 'aarp') {
    <div class="gwc-gov-fee__message">
      <img src="assets/svg/aarp_logo.svg">
      <span>
        Passport Renewal Bundles for AARP Members Include:
        <ul>
          <li>
            A user-friendly online experience 
          </li>
          <li>
            All government and shipping fees
          </li>
          <li>
            Document pre-check for accuracy
          </li>
          <li>
            Concierge-level assistance and dedicated support
          </li>
        </ul>
      </span>
    </div>
  }
} @else if (product_type === 'ca_passport') {
  <span class="gwc-gov-fee__fee">
    *Government fee is not included.
  </span>
} 

