import { Injectable } from '@angular/core'
import { Observable, Subject, BehaviorSubject, map } from 'rxjs'
import { ApiService } from './api.service'
import { ExtraGetFacilityOptions, Facility } from '../types/facility'

@Injectable()

export class LocationsService extends ApiService {
  public router_data
  public locationsSubject: Subject<any> = new Subject<any>()
  public currentLocation: Subject<any>  = new BehaviorSubject({
    location: {
      lat: 25.766206,
      lng: -80.182897
    },
    label: 'Miami, FL 33131, USA'
  })

  getFacilities(lat, lng, extras?: ExtraGetFacilityOptions): Observable<Facility[]> {
    const data: ExtraGetFacilityOptions = {
      radius: 25,
      limit: 25,
      brand: 'fedex',
      category: ['passport-photo'],
      ...extras
    }

    return this.postRequest(`facility/near/${lat}/${lng}`, data)
      .pipe(
        map(o => o.data)
      )
  }

  getFacilitiesByZipCode(zipCode: string, extras?: ExtraGetFacilityOptions): Observable<Facility[]> {
    const data: ExtraGetFacilityOptions = {
      radius: 50,
      limit: 50,
      category: 'photo-print',
      ...extras
    }

    return this.postRequest(`facility/near/${zipCode}`, data)
      .pipe(
        map(o => o.data)
      )
  }

  setCurrentLocation(data) {
    return this.currentLocation.next(data)
  }
}
