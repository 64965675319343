import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, Output, ViewChild } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { debounceTime, distinctUntilChanged, filter, Observable, of, switchMap, tap } from 'rxjs'
import { ShippingService } from 'src/app/services/shipping.service'
import { ShippingForm } from 'src/app/types/shipping'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'gwc-google-address',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatIconModule,
    MatListModule
  ],
  templateUrl: './google.address.component.html',
  styleUrl: './google.address.component.scss'
})

export class GoogleAddressComponent implements AfterViewInit {
  @ViewChild('shippingAddressInput') shippingAddressInput: ElementRef

  @Input() addressControl: FormGroup<ShippingForm>
  @Input() type: string
  @Input() showAddress2 = true
  @Input() label: string = undefined
  @Output() updateMap = new EventEmitter()
  @Output() addressUpdated = new EventEmitter()

  private blurTimeout: NodeJS.Timeout
  private isSelectingPrediction = false
  public predictions: google.maps.places.AutocompletePrediction[] = []
  public previousValue: string = ''
  public showPredictions: boolean = false

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    if (this.showPredictions) {
      const clickedInside = this.elementRef.nativeElement.contains(targetElement)
      if (!clickedInside) {
        this.showPredictions = false
      }
    }
  }

  constructor(
    private shippingService: ShippingService,
    private ngZone: NgZone,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private elementRef: ElementRef
  ) {
    this.matIconRegistry.addSvgIcon(`location`,this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/svg/location.svg")
    )
  }

  ngAfterViewInit(): void {
    this.getPlaceAutocompleteService()
  }

  ngOnDestroy() {
    clearTimeout(this.blurTimeout)
  }

  private getPlaceAutocompleteService(): void {
    let options: any = {}

    if (!environment.source.international) {
      options = {
        componentRestrictions: { country: environment.source.country?.toLowerCase() || 'us' }
      }
    }

    const autocompleteService = new google.maps.places.AutocompleteService()
    this.addressControl.get('address_line').valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter(value => value !== null && value !== undefined),
        switchMap(value => {
          this.showPredictions = true
          if (this.isSelectingPrediction) {
            this.isSelectingPrediction = false
            return of([])
          }
          value = value.trim()
          if (value.length > 0 && value !== this.previousValue) {
            this.previousValue = value
            return this.getPredictions(value, options, autocompleteService)
          } else {
            this.previousValue = value
            return of([])
          }
        })
      )
      .subscribe((predictions: google.maps.places.AutocompletePrediction[]) => {
        this.ngZone.run(() => {
          this.predictions = predictions
        })
      })
  }

  private getPredictions(value: string, options: any, autocompleteService: google.maps.places.AutocompleteService) {
    return new Observable<google.maps.places.AutocompletePrediction[]>(subscriber => {
      autocompleteService.getPlacePredictions (
        { input: value, ...options },
        (predictions: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            subscriber.next(predictions)
          } else {
            subscriber.next([])
          }
          subscriber.complete()
        }
      )
    })
  }

  public selectPrediction(prediction: google.maps.places.AutocompletePrediction): void {
    this.isSelectingPrediction = true
    const placesService = new google.maps.places.PlacesService(
      this.shippingAddressInput.nativeElement
    )
    placesService.getDetails(
      { placeId: prediction.place_id },
      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.saveAddress(place)
          this.ngZone.run(() => {
            this.showPredictions = false
            this.predictions = []
          })
        }
      }
    )
  }

  public saveAddress(place: google.maps.places.PlaceResult): void {
    this.addressControl.get('address_line').patchValue(place.formatted_address)
    this.addressControl.get('place_id').patchValue(place.place_id)
    const url = "https://www.google.com/maps/embed/v1/place?key=AIzaSyDrBuO_eyjOF8o1vZ2bdbOsc3xW6_ALheo&q=place_id:" + place.place_id
    this.updateMap.emit({url})
    let address: any = this.shippingService.getPlaceFromGoogleObject(place)

    if (this.addressControl.get('address').get('address_2')?.value) {
      address.address_2 = this.addressControl.get('address').get('address_2')?.value
    }

    this.addressControl.get('address').patchValue(address)
    this.addressUpdated.emit({ place })
  }

  get addressLine() {
    const response = this.addressControl.get('address_line')
    return response
  }

  public onBlur() {
    this.blurTimeout = setTimeout(() => {
      if (this.addressControl.get('address').get('address_1').invalid || this.addressControl.get('place_id').invalid) {
        this.addressControl.get('address_line').setErrors({'selected': true})
      }
    }, 150)
  }

  highlightMatch(text: string, query: string): SafeHtml {
    if (!query) {
      return this.domSanitizer.bypassSecurityTrustHtml(text)
    }
    const regex = new RegExp(`(${query})`, 'gi')
    const highlightedText = text.replace(regex, '<strong>$1</strong>')
    return this.domSanitizer.bypassSecurityTrustHtml(highlightedText)
  } 
}
