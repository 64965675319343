import {Component, Input, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core'
import { AppleService } from 'src/app/services/apple.service'
import { OrderService } from 'src/app/services/order.service'
import { MerchatToken, OrderCreateSubject } from 'src/app/types/billing'

@Component({
	selector: 'gwc-apple-pay',
	templateUrl: './apple.component.html',
	styleUrls: ['./apple.component.scss'],
	standalone: true,
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppleComponent {
	@Input() domain: string
	@Input() currency: string
	@Input() total: number

	constructor(
		private appleService: AppleService,
		private orderService: OrderService
	) {
	}

	public applePayClicked(event) {
		const request: ApplePayJS.ApplePayPaymentRequest = {
			countryCode: 'US',
			currencyCode: 'USD',
			merchantCapabilities: [
				'supports3DS'
			],
			supportedNetworks: [
					'visa',
					'masterCard',
					'amex',
					'discover'
			],
			total: {
					label: this.appleService.name,
					type: 'final',
					amount: `${this.total}`
			}
		}
		const session: ApplePaySession = new ApplePaySession(14, request)
		session.begin()
		session.onpaymentmethodselected = (event) => {
			session.completePaymentMethodSelection({
				newTotal: {
					label: this.appleService.name,
					type: 'final',
					amount: `${this.total}`
				}
			})
		}

		session.onpaymentauthorized = (event) => {
			this.orderService.getMerchantToken(this.currency, true)
				.subscribe((merchant_response: {data: MerchatToken}) => {
					let objJsonStr = JSON.stringify(event.payment.token.paymentData)
					let objJsonB64 = window.btoa(objJsonStr)

					this.orderService.createOrder({dataDescriptor: 'COMMON.APPLE.INAPP.PAYMENT', dataValue: objJsonB64}, merchant_response.data)
					const orderCreateSubscription = this.orderService.orderCreateSubject.subscribe((status: OrderCreateSubject) => {
						orderCreateSubscription.unsubscribe()
            if (status.success) {
							console.log('success')
              session.completePayment({status: ApplePaySession.STATUS_SUCCESS})
            } else {
							console.log('failed')
							const error = new ApplePayError('unknown', null, 'Please check the card information again or contact your financial instituition.')
							session.completePayment({status: ApplePaySession.STATUS_FAILURE, errors: [error]})
						}
          })
				})
		}

		session.onvalidatemerchant = (event) => {
			this.appleService.startApplePaySession()
				.subscribe({
					next: (response) => {
						if (response.data) {
							session.completeMerchantValidation(response.data)
						} else {
							session.abort()
						}
					},
					error: () => {
						console.log('handle error')
					}
				})
		}
	}
}
