import { Component, Input } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatListModule } from '@angular/material/list'
import { CardModule } from 'src/app/components/card/card.module'
import { OrderService, PhoneObject } from 'src/app/services/order.service'
import { environment } from 'src/environments/environment'

declare global {
	interface Window { LC_API: any; }
}

@Component({
	selector: 'gw-chkout-home-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss'],
	standalone: true,
	imports: [
		CardModule,
		MatButtonModule,
		MatListModule
	]
})

export class ContactComponent {
	@Input() variation: number = 1

	public phone: PhoneObject = this.orderService.phone
	public email: string = environment.support.email
	public livechat: boolean = environment.livechat?.enabled
	public support: string = environment.support.label || 'RUSHMYPASSPORT SUPPORT'
	public signinUrl: string = environment.client_url
	public support_img: string = environment.support.img || 'assets/png/contact.png'

	constructor(
		private orderService: OrderService
	) {}

	public openLiveChat(event): void {
		event.preventDefault()
		try {
			window.LC_API.open_chat_window()
		} catch (e) {}
	}
}
