<section class="gwc-idp-option">
	<div class="gwc-idp-option__main">
		{{ option.meta.processing }}
	</div>
	@for (item of option.meta.items; track item) {
		<div class="gwc-idp-option__item">
			<div>
				{{ item.label }}
			</div>
			<div>
				{{ item.price | currency:option.currency:'symbol':'1.2-2' }}
			</div>
		</div>
	}
	<div class="gwa-idp-option__subtotal">
		<div>
			Subtotal
		</div>
		<div>
			{{ option.default_price | currency:option.currency:'symbol':'1.2-2' }}
		</div>
	</div>
</section>